function errorHandler(errMessage: string) {
  if (!process.dev) {
    const { VITE_API_HOST } = useRuntimeConfig().public
    const { teacherUserInfo: { id, name }} = $stores.user.useUserStore()
    const origin = window ? window.location.href : ''
    const info = `origin：\n  ${origin} \n\nserver：\n  ${VITE_API_HOST} \n\nuser：\n  id：${id}    name：${name} \n\n`
    $fetch('/api/report', {
      method: 'post',
      body: {
        content: info + errMessage
      }
    })
  } else {
    console.error(errMessage)
  }
}

export const $expand = {
  errorHandler
}
