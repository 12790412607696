import { defineStore } from 'pinia'

export const useTestPaperStore = defineStore('testPaper', () => {
  interface QuestionIds {
    id: number,
    createType: enumsTypes.CREATE_TYPE
  }
  const { CREATE_TYPE } = $enums

  const testPaperId = ref<number>(0) // 当前组卷id
  const questionIds = ref<QuestionIds[]>([]) // 已组试卷的题目ids

  function setTestPaperId(id: number) {
    testPaperId.value = id
  }

  function setQuestionIds(data: QuestionIds[]) {
    questionIds.value = data
  }

  // 设置题目是否在组卷中的selected字段
  function setQuestionSelect<T extends databaseTypes.Question | testPaperQuestionModel | databaseTypes.PaperQuestion>(questions: T[], isWrongQuestion = false): T[] {
    console.log('setQuestionSelect', questions)
    questions.forEach((v: any) => {
      if (isWrongQuestion) {
        const isSelect = questionIds.value.find(q => q.id === v.questionId)
        v.selected = isSelect ? 1 : 0
      } else {
        const isSelect = questionIds.value.find(q => q.id === v.id)
        v.selected = isSelect ? 1 : 0
      }
    })

    return questions
  }

  // 判断题目是否在为Ai组题
  function judgeCreateIsByAI(id: number) {
    const currQuestion = questionIds.value.find(v => v.id === id)
    if (!currQuestion) return false

    return currQuestion.createType === CREATE_TYPE.AI
  }

  return {
    testPaperId,
    setTestPaperId,
    questionIds,
    setQuestionIds,
    setQuestionSelect,
    judgeCreateIsByAI
  }
})
