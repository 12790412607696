// 获取绑定的state
export function getWeChatState(key = 'getWeChatState') {
  const url = '/weChat/bind-statePara'
  return $request(key, { url })
}

// 微信绑定用户
export function userBindWeChat(requestData: thirdLoginTypes.userBindWeChat, key = 'userBindWeChat') {
  const url = '/weChat/bind'
  return $request(key, { url }, { query: requestData })
}

// 微信解除绑定
export function userUnBindWeChat(requestData: { userId: number }, key = 'userUnBindWeChat') {
  const { userId } = requestData
  const url = `/weChat/unbind/user/${userId} `
  return $request(key, { url }, { method: 'post' })
}
