// 获取作业列表
export function homeworkList(requestData: homeworkTypes.homeworkListReq, key = 'homeworkList') {
  const url = '/works/list'
  return $request<homeworkTypes.homeworkListRes>(key, { url }, { method: 'post', body: requestData })
}

// 题卡合一获取学生作业
export function getOneMergeHomework(requestData: homeworkTypes.oneMergeHomeworkReq, key = 'getOneMergeHomework') {
  const { homeworkId, page } = requestData
  const url = `/works/${homeworkId}/studentscans/pages/${page}`
  return $request<homeworkTypes.oneMergeHomeworkRes>(key, { url })
}

// 删除作业
export function deleteHomework(id: number, key = 'deleteHomework') {
  const url = `/works/${id}`
  return $request(key, { url }, { method: 'delete' })
}

// 作业题目打分
export function setHomeworkQuestionScore(requestData: homeworkTypes.setHomeworkQuestionScoreReq, key = 'setHomeworkQuestionScore') {
  const url = `/works/${requestData.id}/questions/${requestData.qid}/students/${requestData.studentId}/score/${requestData.score}`
  return $request(key, { url }, { method: 'post' })
}

// 上传作业答案 暂时未使用。。。
export function uploadHomeworkAnswer(requestData: { id: number, file: any }, key = 'uploadHomeworkAnswer') {
  const url = `/works/${requestData.id}/answer`
  return $request(key, { url }, { method: 'post', body: { file: requestData.file }})
}

// 获取作业答案
export function getHomeworkAnswer(id: number, key = 'getHomeworkAnswer') {
  const url = `/works/${id}/answer`
  return $request<homeworkTypes.homeworkAnswer>(key, { url }, { method: 'get' })
}

// 分片上传作业
export function uploadHomeworkChunks(requestData: homeworkTypes.homeworkChunksReq, key = 'uploadHomeworkChunks') {
  const url = `/works/${requestData.id}/answercards/chunks/${requestData.chunk}`
  return $request(key, { url }, { method: 'post', body: requestData.file, query: { serialNum: requestData.serialNum }})
}

// 分片上传作业后合并文件
export function unionHomeworkChunks(requestData: homeworkTypes.homeworkChunksUnionReq, key = 'unionHomeworkChunks') {
  const url = `/works/${requestData.id}/answercards/mergefile`
  return $request(key, { url }, { method: 'post', body: requestData.formData })
}

// 获取上传答题卡状态，是否成功上传，是否完成识别
export function homeworkChunksStatus(id: number, key = 'homeworkChunksStatus') {
  const url = `/works/${id}/answercards/status`
  return $request<homeworkTypes.homeworkAnswerUploadStatus>(key, { url }, { method: 'get' })
}

// 获取学生作业分数
export function homeworkScores(requestData: homeworkTypes.studentscoresReq, key = 'homeworkScores') {
  const { id, q, sort, ...body } = requestData
  const query = { q, sort }
  const url = `/works/${id}/studentscores`
  return $request<homeworkTypes.studentscoresRes>(key, { url }, { method: 'post', body: $utils.isEmpty(body) ? undefined : body, query })
}

// 获取作业下载地址
export function homeworkDownloadUrl(id: number, key = 'homeworkDownloadUrl') {
  const url = `/works/${id}/file/download`
  return $request<homeworkTypes.homeworkDownloadUrlRes>(key, { url }, { method: 'get' })
}

// 获取未正确识别的扫描件
export function getUnrecognizedScans(id: number, key = 'getUnrecognizedScans') {
  const url = `/works/${id}/unrecognizedScans`
  return $request<homeworkTypes.UnrecognizedScans[]>(key, { url })
}

// 修改扫描件
export function inputStudentNumber(requestData: homeworkTypes.inputStudentNumberReq, key = 'inputStudentNumber') {
  const { id, scanId, ...studentNumber } = requestData
  const url = `/works/${id}/scans/${scanId}`
  return $request(key, { url }, { method: 'put', body: studentNumber })
}

// 删除扫描件
export function removeUnrecognizedScans(requestData: {id: string, scanId: string}, key = 'removeUnrecognizedScans') {
  const { id, scanId } = requestData
  const url = `/works/${id}/scans/${scanId}`
  return $request<null>(key, { url }, { method: 'delete' })
}

// 获取某个未识别成功作业的url
export function getAnswerSheetUrl(requestData: homeworkTypes.ExamAnswerSheet, key = 'getAnswerSheetUrl') {
  const { id, scanId } = requestData
  const url = `/works/${id}/scans/${scanId}`
  return $request<homeworkTypes.AnswerSheetUrlRes>(key, { url })
}

// 获取作业数据表格
export function downloadHomeworkDetailFile(requestData: homeworkTypes.downloadHomeworkDetailFileReq, key = 'downloadHomeworkDetailFile') {
  const url = `/works/file`
  return $request<{recordId: number}>(key, { url }, { method: 'post', body: requestData })
}

// 获取学生作业扫描件
export function getStudentHomework(requestData: homeworkTypes.getStudentHomeworkReq, key = 'getStudentHomework') {
  const url = `/works/${requestData.workId}/students/${requestData.studentId}/scan`
  return $request<homeworkTypes.getStudentHomeworkRes>(key, { url }, { method: 'get', query: { page: requestData.page }})
}

// 获取作业选择题答案
export function getHomeworkSelectAnswer(id: number, key = 'getHomeworkSelectAnswer') {
  const url = `/works/${id}/choice/answer`
  return $request<homeworkTypes.SelectQuestionAnswerRes>(key, { url }, { method: 'get' })
}

// 修改作业选择题答案
export function changeHomeworkSelectAnswer(requestData: homeworkTypes.selectQuestionChangeReq, key = 'changeHomeworkSelectAnswer') {
  const url = `/works/${requestData.id}/choice/answer`
  return $request(key, { url }, { method: 'PATCH', body: requestData.questions })
}

// 修改校本记录展示设置
export function setHomeworkIsShare(requestData: project.IsShareModel, key = 'setHomeworkIsShare') {
  const { id, isShare } = requestData
  const url = `/works/${id}/pub?pub=${isShare}`
  return $request(key, { url }, { method: 'put' })
}

// 修改作业批改学生人数
export function setHomeworkCorrectNum(requestData: homeworkTypes.SetStudentCorrectNum, key = 'setHomeworkCorrectNum') {
  const { id, ...body } = requestData
  const url = `/works/${id}/check/students`
  return $request(key, { url }, { method: 'put', body })
}

// 作业学生得分情况
export function getStudentEachQuestionScore(requestData: homeworkTypes.getStudentEachQuestionScoreReq, key = 'getStudentEachQuestionScore') {
  const url = `/works/${requestData.workId}/students/${requestData.studentId}/questionscore`
  return $request<homeworkTypes.getStudentEachQuestionScoreReq>(key, { url }, { method: 'get' })
}
