export function logout() {
  const kc = $utils.getStorage('loginState')
  $utils.removeStorage('loginState')
  window.location.replace(createLogoutUrl(kc))
}

function createLogoutUrl(kc) {
  const url = getRealmUrl(kc) + '/protocol/openid-connect/logout' +
            '?post_logout_redirect_uri=' + encodeURIComponent(window.location.origin) +
            '&id_token_hint=' + encodeURIComponent(kc.idToken)

  return url
}

function getRealmUrl(kc) {
  if (typeof kc.authServerUrl !== 'undefined') {
    if (kc.authServerUrl.charAt(kc.authServerUrl.length - 1) === '/') {
      return kc.authServerUrl + 'realms/' + encodeURIComponent(kc.realm)
    } else {
      return kc.authServerUrl + '/realms/' + encodeURIComponent(kc.realm)
    }
  } else {
    return undefined
  }
}

export function jugeTokenIsExp() {
  const idToken = $utils.getStorage('loginState')?.idToken
  const payload = JSON.parse(window.atob(idToken.split('.')[1]))
  return payload.exp * 1000 < new Date().getTime()
}
