// 添加学生信息
export function addStudent(requestData: studentManageTypes.addStudentModel, key = 'addStudent') {
  const url = `student`
  return $request(key, { url }, { method: 'post', body: requestData })
}

// 获取学生信息列表
export function getStudentList(requestData, key = 'getStudentList') {
  const url = `student/list`
  return $request<studentManageTypes.studentTableModel>(key, { url }, { method: 'post', body: requestData })
}

// 删除学生信息
export function removeStudent(requestData: Pick<studentManageTypes.studentModel, 'id'>, key = 'removeStudent') {
  const { id } = requestData
  const url = `student/${id}`
  return $request<null>(key, { url }, { method: 'delete' })
}

// 根据id获取学生信息
export function getStudentById(requestData: Pick<studentManageTypes.studentModel, 'id'>, key = 'getStudentById') {
  const { id } = requestData
  const url = `student/${id}`
  return $request<studentManageTypes.updateStudentModel>(key, { url }, { method: 'get' })
}

// 修改学生信息
export function updateStudent(requestData: studentManageTypes.updateStudentModel, key = 'updateStudent') {
  const url = `student`
  return $request<null>(key, { url }, { method: 'put', body: requestData })
}

// 上传学生信息文件
export function uploadStudentFile(requestData: { formData: FormData }, key = 'uploadStudentFile') {
  const { formData } = requestData
  const url = `/student/upload`
  return $request(key, { url }, { method: 'post', body: formData })
}

// 自动生成学生号
export function generateStudentCode(requestData: Pick<studentManageTypes.studentModel, 'groupId'>, key = 'generateStudentCode') {
  const url = '/student/code'
  return $request<studentManageTypes.generateStudentCode>(key, { url }, { params: requestData })
}

// 学情报告--获取学生做题记录
export function getStudentDoQuestion(requestData: studentManageTypes.LearnResultsParams, key = 'getStudentDoQuestion') {
  const { studentId, subject, ...query } = requestData
  const url = `/learnresults/students/${studentId}/subjects/${subject}/questions/number`
  return $request<DoQuestionChartModel[]>(key, { url }, { query })
}

// 学情报告--获取题目难度记录
export function getQuestionDifficulty(requestData: studentManageTypes.LearnResultsParams, key = 'getQuestionDifficulty') {
  const { studentId, subject, ...query } = requestData
  const url = `/learnresults/students/${studentId}/subjects/${subject}/questions/difficulty`
  return $request<QuestionDifficultyChartModel[]>(key, { url }, { query })
}

// 学情报告--获取知识点统计
export function getKnowledgeStatistic(requestData: studentManageTypes.LearnResultsParams, key = 'getKnowledgeStatistic') {
  const { studentId, subject, ...query } = requestData
  const url = `/learnresults/students/${studentId}/subjects/${subject}/knowledgepoints/total`
  return $request<KnowledgeStatisticChartModel>(key, { url }, { query })
}

// 学情报告--获取作业和考试记录
export function getHomeworkAndExamReport(requestData: studentManageTypes.LearnResultsParams, key = 'getHomeworkAndExamReport') {
  const { studentId, subject, ...query } = requestData
  const url = `/learnresults/students/${studentId}/subjects/${subject}/scores`
  return $request<HomeworkAndExamChartModel[]>(key, { url }, { query })
}

// 学情报告--获取学生知识点列表
export function getStudentKnowledgePoints(requestData: studentManageTypes.KnowledgePointsReq, key = 'getStudentKnowledgePoints') {
  const { studentId, subject, ...body } = requestData
  const url = `/learnresults/students/${studentId}/subjects/${subject}/knowledgepoints/list`
  return $request<KnowlegePointsChartModel>(key, { url }, { method: 'post', body })
}

// 生成班级学情报告
export function genStudentStudyReport(requestData: studentManageTypes.GenStudentStudyReportReq, key = 'genStudentStudyReport') {
  const { studentId, subject, ...body } = requestData
  const url = `/learnresults/students/${studentId}/subjects/${subject}/file`
  return $request<{ recordId: number }>(key, { url }, { method: 'post', body })
}
