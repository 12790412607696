import { defineAsyncComponent } from 'vue'
import { defineNuxtPlugin } from '#app/nuxt'

const components = {
  Icon: defineAsyncComponent(() => import("/app/node_modules/nuxt-icon/dist/runtime/Icon.vue" /* webpackChunkName: "components/icon" */).then(c => c.default || c)),
  IconCSS: defineAsyncComponent(() => import("/app/node_modules/nuxt-icon/dist/runtime/IconCSS.vue" /* webpackChunkName: "components/icon-css" */).then(c => c.default || c))
}

export default defineNuxtPlugin(nuxtApp => {
  for (const name in components) {
    nuxtApp.vueApp.component(name, components[name])
    nuxtApp.vueApp.component('Lazy' + name, components[name])
  }
})
