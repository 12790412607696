import { $fetch, FetchOptions, FetchRequest } from 'ofetch'
import { _Transform, KeyOfRes, PickFrom } from 'nuxt/dist/app/composables/asyncData'
import { AsyncDataOptions, AsyncData } from '#app'

let requestCounts = 0
let timer: NodeJS.Timeout

function isCreateTimer(request: FetchRequest, options: FetchOptions) {
  const method = (options.method || 'get').toLowerCase()
  const urlKey = `${request}|${method}`
  const { updateLoading, addToRequestKeyArr } = $stores.app.useAppStore()
  addToRequestKeyArr(urlKey)
  requestCounts++
  if (!timer) {
    timer = setTimeout(() => {
      updateLoading(true)
    }, 1000)
  }
}

function isClearTimer(request: FetchRequest, options: FetchOptions) {
  request = (request as string).replace(options.baseURL || '', '')
  request = request.split('?')[0]
  const method = (options.method || 'get').toLowerCase()
  const urlKey = `${request}|${method}`

  const { updateLoading, removeFromRequestKeyArr } = $stores.app.useAppStore()
  removeFromRequestKeyArr(urlKey)
  requestCounts--
  if (requestCounts <= 0) {
    clearTimeout(timer)
    updateLoading(false)
  }
}

const service = $fetch.create({
  async onRequest({ options, request }) {
    // const { VITE_API_HOST } = useRuntimeConfig().public
    // if ((request as string).indexOf('http') !== 0) {
    //   options.baseURL = VITE_API_HOST
    // }
    options.headers = options.headers || {}
    // const loginState = $utils.getStorage('loginState')
    // if (loginState) {
    //   Reflect.set(options.headers, 'Authorization', loginState.idToken)
    // }
    isCreateTimer(request, options)
  },
  async onRequestError({ request, options, error }) {
    // Log error
    isClearTimer(request, options)
    const message = '[fetch request error]' + request + error + options
    $notify({
      type: 'error',
      message: '网络错误'
    })
    $expand.errorHandler(message)
  },
  // eslint-disable-next-line
  async onResponse({ request, response, options }) {
    isClearTimer(request, options)
    return new Promise((resolve, reject) => {
      if (response.status === 401) {
        $notify({
          type: 'error',
          message: '登录状态过期，请重新登录！'
        })
        $utils.oauthLogout()
      }
      if (!response._data?.errorCode) {
        resolve((response as any))
      } else {
        const message = `url：\n  ${request} \n\nmethod：${options.method || 'get'} \n\nstatus：${response.status} \n\nmessage：\n  ${JSON.stringify(response._data)}`
        $notify({
          type: 'error',
          message: '请求失败 - ' + (response._data.message || '未知错误')
        })
        $expand.errorHandler(message)
        reject(response._data)
      }
    })
  },
  async onResponseError({ request, response, options }) {
    isClearTimer(request, options)

    $notify({
      type: 'error',
      message: '请求失败 - ' + response.status
    })
    console.log('optionsoptionsoptions', options, response)
    const message = `url：\n  ${request} \n\nmethod：${options.method || 'get'} \n\nstatus：${response.status} \n\ninfo：\n  ${JSON.stringify(response._data)} \n\nbody：\n  ${JSON.stringify(options.body)}`
    $expand.errorHandler(message)
  }
})

/**
 * 发送请求
 * @date 2022-09-16
 * @param {any} key:string 请求的键
 * @param {any} request:{ url: string, req: any } url：请求地址
 * @param {any} fetchOptions?:FetchOptions 请求配置项 https://github.com/unjs/ohmyfetch
 * @param {any} options?:AsyncDataOptions 请求渲染配置项
 * @returns {any}
 */
export function $request<DataT, DataE = Error, Transform extends _Transform<DataT> = _Transform<DataT, DataT>, PickKeys extends KeyOfRes<Transform> = KeyOfRes<Transform>>(key: string, request: RequestObj, fetchOptions?: FetchOptions<ResponseTypeCustom>, options?: AsyncDataOptions<DataT, Transform, PickKeys>): AsyncData<PickFrom<ReturnType<Transform>, PickKeys>, DataE | null | true> {
  options = {
    // initialCache: false, // 关闭请求缓存
    server: true, // 开发环境可关闭server端请求方便调试
    ...options
  }
  return useAsyncData<DataT, DataE, Transform, PickKeys>(key, () => {
    let url = request.url
    if (request.req) {
      Reflect.ownKeys(request.req).forEach(key => {
        url = url.replaceAll(`{${key as string}}`, request.req[key])
      })
    }

    const method = fetchOptions?.method || ''
    if ((method === 'post' || method === 'put' || method === 'PATCH') && !fetchOptions?.body) {
      (fetchOptions as FetchOptions<ResponseTypeCustom>).body = request.req
    }
    const { VITE_API_PREFFIX } = useRuntimeConfig().public
    if (request.isOther) {
      url = '/' + url.replace(/^\//, '')
    } else if (url.indexOf('http') !== 0) {
      url = VITE_API_PREFFIX + url.replace(/^\//, '')
    }
    return service<DataT, ResponseTypeCustom>(url, fetchOptions) as Promise<DataT>
  }, options)
}

