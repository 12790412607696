/**
 * 说明：用户信息枚举
*/

/** ================================================ 角色身份--start ================================================ **/
export enum ROLE {
  ADMIN = 'Admin', // 校长、系统管理员
  VICE_PRINCIPAL = 'VicePrincipal', // 副校长
  DEAN = 'Dean', // 教务处
  GRADE_LEADER = 'GradeLeader', // 年级组长
  SUBJECT_LEADER = 'SubjectLeader', // 学科组长
  CLASS_TEACHER = 'ClassTeacher', // 班主任
  TEACHER = 'Teacher' // 教师
}
export const roleList: labelValueList<ROLE> = [
  { label: '副校长', value: ROLE.VICE_PRINCIPAL },
  { label: '教务处', value: ROLE.DEAN },
  { label: '年级组长', value: ROLE.GRADE_LEADER },
  { label: '学科组长', value: ROLE.SUBJECT_LEADER },
  { label: '班主任', value: ROLE.CLASS_TEACHER },
  { label: '任课教师', value: ROLE.TEACHER }
]
export enum GENDER {
  MALE = 'Male', // 男
  FEMALE = 'Female' // 女
}
export const genderList: labelValueList<GENDER> = [
  { label: '男', value: GENDER.MALE },
  { label: '女', value: GENDER.FEMALE }
]
/** ================================================ 角色身份--end ================================================ **/
