import { computed } from 'vue'
/**
   * 判断传入数据是否为 undefined
   * @param value
   * @returns {boolean}
   */
export function isUndefined(value): boolean {
  return Object.prototype.toString.call(value) === '[object Undefined]'
}

/**
* 判断传入数据是否为 null
* @param value
* @returns {boolean}
*/
export function isNull(value: string): boolean {
  return Object.prototype.toString.call(value) === '[object Null]'
}

/**
* 判断传入数据是否为 String
* @param value
* @returns {boolean}
*/
export function isString(value): boolean {
  return Object.prototype.toString.call(value) === '[object String]'
}

/**
* 判断传入数据是否为 Object
* @param value
* @returns {boolean}
*/
export function isObject(value): boolean {
  return Object.prototype.toString.call(value) === '[object Object]'
}

/**
* 判断传入数据是否为 Array
* @param value
* @returns {boolean}
*/
export function isArray(value): boolean {
  return Object.prototype.toString.call(value) === '[object Array]'
}

/**
* 判断传入数据是否为空，包含 undefined、null、空字符串（只有空格的字符串）、空数组、空对象
* @example isEmpty('   ') // => true
* @param {*} value 要判断的数据
* @returns {boolean}
*/
export function isEmpty(value): boolean {
  if (
    isUndefined(value) ||
     isNull(value) ||
     (isString(value) && value.replace(/(^\s*)|(\s*$)/g, '').length === 0) ||
     (isObject(value) && !Object.keys(value).length) ||
     (isArray(value) && !value.length)
  ) {
    return true
  }
  return false
}

/**
 * 本地存储
 * @date 2022-08-09
 * @param {string} key 需要保存的键
 * @param {string | Object} value 需要保存的值
 * @param {boolean} isSession 是否存在sessionStorage
 */
export function setStorage(key: string, value: any, isSession?: boolean): void {
  if (typeof key !== 'string') {
    console.error('please use the right key')
  }
  if (isEmpty(value)) {
    console.warn('if you want clear the storage, please use removeStorage function')
    return
  }
  let str
  if (value instanceof Object) {
    str = JSON.stringify(value)
  } else {
    str = value
  }
  if (isSession) {
    window.sessionStorage.setItem(key, str)
  } else {
    window.localStorage.setItem(key, str)
  }
}

/**
 * 读取本地存储
 * @date 2022-08-09
 * @param {string} key 需要读取的键
 * @param {boolean} isSession 是否读取sessionStorage
 * @param {boolean} disabledTrans 是否不自动解析为JSON对象
 * @returns {string | Object}  读取的值
 */
export function getStorage<T>(key: string, isSession?: boolean, disabledTrans?: boolean): T {
  if (typeof key !== 'string') {
    console.error('please use the right key')
  }
  let value
  if (isSession) {
    value = window.sessionStorage.getItem(key)
  } else {
    value = window.localStorage.getItem(key)
  }
  if (disabledTrans) return value
  try {
    value = JSON.parse(value)
  } catch (error) {
    null
  }
  return value
}
/**
* 删除本地存储
* @date 2022-08-09
* @param {any} key 需要删除的键
* @param {boolean} isSession 是否删除sessionStorage
*/
export function removeStorage(key: string, isSession?: boolean) {
  if (isSession) {
    window.sessionStorage.removeItem(key)
  } else {
    window.localStorage.removeItem(key)
  }
}
/**
 * 获取文件的后缀名
 * @date 2022-08-12
 * @param {string} fileName 文件名
 * @returns {string} 文件的后缀名，不带 . 不带 . 不带 . 重要的事情说三遍 🤨
 */
export function getSuffix(fileName: string) {
  if (!isString(fileName)) return ''
  const arr = fileName.split('.')
  let suffix = ''
  if (arr.length > 1) {
    suffix = arr.pop() || ''
  }
  return suffix
}

/**
 * 把知识点处理成分层级数据
 * @date 2022-10-11
 * @param {Array} knowledgeList 所有未分级知识点数组
 * @returns {Array} 已分级数组
*/
export function dealWithKnowledge(knowledgeList: any[]): any[] {
  knowledgeList = JSON.parse(JSON.stringify(knowledgeList)) // 深拷贝一份防止数据多次渲染
  const noLevelOneknowledgePoints: any[] = []
  // 将所有有父级知的筛选出来
  knowledgeList.forEach(item => {
    if (item.parentPointId) {
      noLevelOneknowledgePoints.push(item)
    }
    delete item.children
  })
  const tempArr = knowledgeList.filter((parent) => {
    knowledgeList.forEach((child) => {
      if (child.parentPointId === parent.id) {
        if (parent.children) {
          parent.children.push(child)
        } else {
          parent.children = [child]
        }
        // 将已找到父级的存入父级，并在noLevelOneknowledgePoints删除
        const idx = noLevelOneknowledgePoints.indexOf(child)
        noLevelOneknowledgePoints.splice(idx, 1)
      }
    })
    parent.label = parent.name
    parent.value = parent.id
    return !parent.parentPointId
  })
  return [...tempArr, ...noLevelOneknowledgePoints]
}

/**
 * 把需隐藏的知识点剔除
 * @date 2023-07-19
 * @param {Array} knowledgeList 所有已分级知识点数组
 * @returns {Array} 已剔除需隐藏知识点的数组
*/
export function handleHideKnowledge(knowledgeList: any[]): any[] {
  const copyData = deepCloneWithJSON(knowledgeList)
  copyData.forEach(node => {
    if (node.children) {
      if (node.isHide) {
        Reflect.deleteProperty(node, 'children')
      } else {
        node.children = handleHideKnowledge(node.children)
      }
    }
  })
  return copyData
}

export function readOnlyMathField() {
  const readOnlyMathFieldList = window.document.querySelectorAll('.read_only_math_field')
  if (!readOnlyMathFieldList) return

  readOnlyMathFieldList.forEach(item => {
    if (item.__isRender) return
    const style = document.createElement('style')
    style.innerHTML = `
      .ML__mathlive {
        width: 100%;
        white-space: normal;
        word-wrap: break-word;
        word-break: break-all;
      }
      .ML__base {
        width: 100%;
        line-height: 2;
      }
    `
    item.shadowRoot.appendChild(style)
    item.__isRender = true
  })
}

export function deepCloneWithJSON<T>(json: T): T {
  return JSON.parse(JSON.stringify(json))
}
/**
 * 描述
 * @date 2022-11-10
 * @param {string} url 下载地址
 * @param {string} fileName 下载的文件名
 */
export async function downloadFile(url: string, fileName: string) {
  const downloadRes: Blob = await $fetch(url, {
    responseType: 'blob',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
  const blob = new Blob([downloadRes])
  const elink = document.createElement('a')
  elink.download = fileName
  elink.style.display = 'none'
  elink.href = URL.createObjectURL(blob)
  // document.body.appendChild(elink)
  elink.click()
  URL.revokeObjectURL(elink.href) // 释放URL 对象
  // document.body.removeChild(elink)
  return downloadRes
}

export async function urlToFile(url: string, fileType: string, fileName = '文件') {
  return new Promise<File>((resolve, reject) => {
    $fetch<Blob>(url, {
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json;charset=UTF-8'
      }
    }).then(res => {
      const blob = new Blob([res])
      const imgFile = new File([blob], fileName, { type: fileType })
      resolve(imgFile)
    }).catch(err => {
      reject(err)
    })
  })
}

export function fileToBase64(file: File) {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader()
    let imgResult: any = ''
    reader.readAsDataURL(file)
    reader.onload = () => {
      imgResult = reader.result
    }
    reader.onerror = (error) => {
      reject(error)
    }
    reader.onloadend = () => {
      resolve(imgResult)
    }
  })
}

/**
   * 把成树组件输出值转化成需要储存的数据结构
   * @date 2022-10-24
   * @param {Array} checkedList 树组件的勾选节点组成的数组
   * @returns {Array} 需要储存的一维数组
   */
export function setKnowledgeTreeValue(checkedList) {
  let copyList = JSON.parse(JSON.stringify(checkedList))

  const parentPointIdObj: any = {}
  for (const node of copyList) {
    parentPointIdObj[node.id] = true
  }
  copyList = copyList.filter((item: any) => {
    return !item.parentPointId || !parentPointIdObj[item.parentPointId]
  })

  return copyList.map(v => v.id)
}

/**
 * 阿拉伯数字转成中文
 * @date 2022-12-06
 * @param {number} num
 * @returns {string}
 */
export function intToChinese(num: number) : string {
  const arr1 = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九']
  const arr2 = ['', '十', '百', '千', '万', '十', '百', '千', '亿', '十', '百', '千', '万', '十', '百', '千', '亿']
  if (!num || isNaN(num)) return '零'
  const numStr = num.toString().split('')
  let result = ''
  for (let i = 0; i < numStr.length; i++) {
    const des_i = numStr.length - 1 - i
    result = arr2[i] + result
    const arr1_index = numStr[des_i]
    result = arr1[arr1_index] + result
  }
  result = result.replace(/零(千|百|十)/g, '零').replace(/十零/g, '十')
  result = result.replace(/零+/g, '零')
  result = result.replace(/零亿/g, '亿').replace(/零万/g, '万')
  result = result.replace(/亿万/g, '亿')
  result = result.replace(/零+$/, '')
  result = result.replace(/^一十/g, '十')
  return result
}

function getCanvasBounding(pointList: project.pointPos[]) {
  const xMin = useMinBy(pointList, v => v.x)?.x
  if (typeof xMin !== 'number') return false

  const xMax = useMaxBy(pointList, v => v.x)?.x
  if (typeof xMax !== 'number') return false

  const yMin = useMinBy(pointList, v => v.y)?.y
  if (typeof yMin !== 'number') return false

  const yMax = useMaxBy(pointList, v => v.y)?.y
  if (typeof yMax !== 'number') return false

  return {
    xMin,
    xMax,
    yMin,
    yMax
  }
}

/**
 * 描述 根据多个点的坐标，把图片裁切成矩形
 * @date 2023-03-21
 * @param {string} sourceUrl:string 需要裁切的原始图片
 * @param {pointPos[]} pointList:pointPos[] 需要裁切的点坐标
 * @returns {Promise<string>} 裁切后的图片
 */
export function getClippedImg(sourceImg: string | HTMLImageElement, pointList: project.pointPos[]) {
  const canvas = document.createElement('canvas') as HTMLCanvasElement

  const bounding = getCanvasBounding(pointList)

  if (!bounding) return console.error('题目边界数据无效')

  const { xMin, xMax, yMin, yMax } = bounding

  canvas.width = xMax - xMin
  canvas.height = yMax - yMin
  const context = canvas.getContext('2d') as CanvasRenderingContext2D
  let img: HTMLImageElement
  const isUrl = typeof sourceImg === 'string'
  if (isUrl) {
    img = new Image()
    img.src = sourceImg
    img.setAttribute('crossOrigin', 'Anonymous')
  } else {
    img = sourceImg
  }

  return new Promise<string>((res, rej) => {
    function clip() {
      context.drawImage(img, xMin, yMin, canvas.width, canvas.height, 0, 0, canvas.width, canvas.height)
      const url = canvas.toDataURL()
      res(url)
    }
    if (img.complete) {
      clip()
    }
    img.onload = clip
    img.onerror = function() {
      rej('加载图片失败')
    }
  })
}

export function getMergedImg(sourceImgList: (string | HTMLImageElement)[]) {
  const canvas = document.createElement('canvas') as HTMLCanvasElement

  const promiseList: Promise<HTMLImageElement>[] = []

  let maxWidth = 0
  let totalHeight = 0
  sourceImgList.forEach((sourceImg) => {
    let img: HTMLImageElement
    const isUrl = typeof sourceImg === 'string'
    if (isUrl) {
      img = new Image()
      img.src = sourceImg
      img.setAttribute('crossOrigin', 'Anonymous')
    } else {
      img = sourceImg
    }

    promiseList.push(new Promise<HTMLImageElement>((resolve, reject) => {
      img.onload = function() {
        maxWidth = Math.max(img.width, maxWidth)
        totalHeight += img.height
        resolve(img)
      }
      img.onerror = function() {
        reject('加载图片失败')
      }
    }))
  })

  return Promise.all(promiseList).then(res => {
    canvas.width = maxWidth
    canvas.height = totalHeight
    const context = canvas.getContext('2d') as CanvasRenderingContext2D

    let targetY = 0
    res.forEach(item => {
      context.drawImage(item, 0, targetY)
      targetY += item.height
    })
    const url = canvas.toDataURL()
    return new Promise<string>((res) => {
      res(url)
    })
  })
}

export function base64ToFile(base64: string, fileName: string) {
  const arr = base64.split(',')
  const matched = arr[0].match(/:(.*?);/)
  if (!matched) {
    throw new Error('无效的图片')
  }
  const mime = matched[1]
  const bstr = window.atob(arr[1])
  let n = bstr.length
  const u8arr = new Uint8Array(n)

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  return new File([u8arr], fileName, { type: mime })
}

export function getGradeByGradeCateGory(gradeCateGory: enumsTypes.GRADE_CATEGORY) {
  const { GRADE_CATEGORY, GRADE } = $enums
  switch (gradeCateGory) {
    case GRADE_CATEGORY.GRADE_1_ONE:
    case GRADE_CATEGORY.GRADE_1_TWO:
      return GRADE.GRADEONE
    case GRADE_CATEGORY.GRADE_2_ONE:
    case GRADE_CATEGORY.GRADE_2_TWO:
      return GRADE.GRADETWO
    case GRADE_CATEGORY.GRADE_3_ONE:
    case GRADE_CATEGORY.GRADE_3_TWO:
      return GRADE.GRADETHREE
    case GRADE_CATEGORY.GRADE_4_ONE:
    case GRADE_CATEGORY.GRADE_4_TWO:
      return GRADE.GRADEFOUR
    case GRADE_CATEGORY.GRADE_5_ONE:
    case GRADE_CATEGORY.GRADE_5_TWO:
      return GRADE.GRADEFIVE
    case GRADE_CATEGORY.GRADE_6_ONE:
    case GRADE_CATEGORY.GRADE_6_TWO:
      return GRADE.GRADESIX
    case GRADE_CATEGORY.GRADE_7_ONE:
    case GRADE_CATEGORY.GRADE_7_TWO:
      return GRADE.GRADESEVEN
    case GRADE_CATEGORY.GRADE_8_ONE:
    case GRADE_CATEGORY.GRADE_8_TWO:
      return GRADE.GRADEEIGHT
    case GRADE_CATEGORY.GRADE_9_ONE:
    case GRADE_CATEGORY.GRADE_9_TWO:
      return GRADE.GRADENINE
    case GRADE_CATEGORY.GRADE_10_ONE:
    case GRADE_CATEGORY.GRADE_10_TWO:
      return GRADE.GRADETEN
    case GRADE_CATEGORY.GRADE_11_ONE:
    case GRADE_CATEGORY.GRADE_11_TWO:
      return GRADE.GRADEELEVEN
    case GRADE_CATEGORY.GRADE_12_ONE:
    case GRADE_CATEGORY.GRADE_12_TWO:
      return GRADE.GRADETWELVE
  }
}

/**
 * 修改级联组件的输入值和输出值，把二维数组结构转化成一维数组结构，并且当父节点全选时，只存储父节点的值
 * @date 2022-08-05
 */
/**
 * 把储存的数据结构转换成级联组件需要的输入值
 * @date 2022-08-05
 * @param {Array} options // 级联组件的options，树形结构
 * @param {Array} checkedValues // 转化之后的一维数组，包含勾选的节点的值
 * @returns {Array} // 级联组件的输入值，二维数组
 */
export function getValue(options, checkedValues) {
  if (!options || !checkedValues) return []

  // 优化内存考虑，没有使用递归 😅
  checkedValues = [...checkedValues]
  const stack = JSON.parse(JSON.stringify(options))
  const idList = [] // 子节点到根节点组成id数组
  const ValueList = [] // 最终返回的数组，即级联组件需要的二级数组结构
  let checkAllNode = null
  let len = 0
  while ((len = stack.length)) {
    const curNode = stack[len - 1]
    let curNodeIndex
    if (curNode.id) {
      curNodeIndex = checkedValues.indexOf(curNode.id)
    } else {
      curNodeIndex = checkedValues.findIndex(item => {
        if (item.value) {
          return item.value === curNode.value
        }
        return false
      })
    }

    if (curNode.__pending) { // 如果有__pending属性，说明该节点的所有子孙节点已处理完成，弹栈
      if (curNode === checkAllNode) {
        checkAllNode = null
      }
      delete curNode.__pending // 删除额外添加的__pending属性
      idList.pop()
      stack.pop()
    } else if (curNode.children) { // 如果有子节点
      if (curNodeIndex !== -1) {
        checkAllNode = curNode // 如果单前节点时勾选的，则把当前节点放到临时变量里面用来做判断
      }

      curNode.children.forEach(node => { // 把所有子节点压栈
        stack.push(node)
      })
      idList.push(curNode.id ? curNode.id : curNode.value) // 记录当前节点的id
      curNode.__pending = true // 表明正在处理该节点的子节点
    } else { // 如果时叶子节点
      if (checkAllNode || curNodeIndex !== -1) { // 如果祖级节点是勾选的，或者自身时勾选的，则把根节点到该节点的所有id组成的数组push到最终的返回值
        ValueList.push([...idList, curNode.id ? curNode.id : curNode.value])
      }
      stack.pop() // 没你事了，弹栈
    }
  }
  return ValueList
}
/**
 * 把成级联组件输出值转化成需要储存的数据结构
 * @date 2022-08-05
 * @param {Array} ValueList 级联组件的勾选节点组成的数组
 * @returns {Array} 需要储存的一维数组
 */
export function setValue(checkedList: any[]) { // 通过value的类型鉴别是否为真正的知识点还是代码临时添加的，或许有某种风险
  const checkedValues: any[] = []
  interface Ids {
    [key: string | number]: boolean
  }
  const ids: Ids = {}

  checkedList.forEach((item: any) => {
    if (typeof (item.value) === 'number' || item.value.includes('custom')) {
      ids[item.value] = true
    }
  })
  checkedList.forEach((item: any) => {
    if ((!item.parent || !ids[item.parent.data.id]) && (typeof (item.value) === 'number' || item.value.includes('custom'))) {
      if (typeof item.value === 'string') {
        checkedValues.push({
          value: item.value,
          label: item.label
        })
      } else {
        checkedValues.push(item.value)
      }
    }
  })
  return checkedValues
}

/**
 * 把班级类型转成 key-value 形式
 * @date 2023-08-09
 * @param {Array} list 班级类型分级数组
 * @returns {Obj} 按key-value 形式返回班级类型分级数组，key为类型，value为同一等级不同类型组成的数组
 */
export function getLevelClassificationObj(list: any) {
  const newObj: any = {}
  list.forEach((item: any) => {
    item.list.forEach((v: any) => {
      newObj[v] = item.list
    })
  })
  return newObj
}

/**
 * 时间戳转换
 * @date 2023-08-31
 * @param {timestamp} string 时间戳
 * @param {full} boolean 是否返回时分秒
 * @returns {string}
 */
export function timestampTrans(timestamp: string | Date, full = false) {
  if (!timestamp) return ''

  const date = new Date(timestamp)
  const yMonDay = date.toLocaleDateString().replace(/\//g, '-')
  const hMinSec = date.toLocaleTimeString()
  if (full) {
    return yMonDay + ' ' + hMinSec
  } else {
    return yMonDay
  }
}

const floatObj = (function() {
  /*
   * 判断obj是否为一个整数
   */
  function isInteger(obj) {
    return Math.floor(obj) === obj
  }

  /*
   * 将一个浮点数转成整数，返回整数和倍数。如 3.14 >> 314，倍数是 100
   * @param floatNum {number} 小数
   * @return {object}
   *   {times:100, num: 314}
   */
  function toInteger(floatNum) {
    const ret = { times: 1, num: 0 }
    const isNegative = floatNum < 0
    if (isInteger(floatNum)) {
      ret.num = floatNum
      return ret
    }
    const strfi = floatNum + ''
    const dotPos = strfi.indexOf('.')
    const len = strfi.substr(dotPos + 1).length
    const times = Math.pow(10, len)
    let intNum = parseInt(Math.abs(floatNum) * times + 0.5, 10)
    ret.times = times
    if (isNegative) {
      intNum = -intNum
    }
    ret.num = intNum
    return ret
  }

  /*
   * 核心方法，实现加减乘除运算，确保不丢失精度
   * 思路：把小数放大为整数（乘），进行算术运算，再缩小为小数（除）
   *
   * @param a {number} 运算数1
   * @param b {number} 运算数2
   * @param digits {number} 精度，保留的小数点数，比如 2, 即保留为两位小数
   * @param op {string} 运算类型，有加减乘除（add/subtract/multiply/divide）
   *
   */
  function operation(a, b, digits, op) {
    const o1 = toInteger(a)
    const o2 = toInteger(b)
    const n1 = o1.num
    const n2 = o2.num
    const t1 = o1.times
    const t2 = o2.times
    const max = t1 > t2 ? t1 : t2
    let result = null
    switch (op) {
      case 'add':
        if (t1 === t2) { // 两个小数位数相同
          result = n1 + n2
        } else if (t1 > t2) { // o1 小数位 大于 o2
          result = n1 + n2 * (t1 / t2)
        } else { // o1 小数位 小于 o2
          result = n1 * (t2 / t1) + n2
        }
        return result / max
      case 'subtract':
        if (t1 === t2) {
          result = n1 - n2
        } else if (t1 > t2) {
          result = n1 - n2 * (t1 / t2)
        } else {
          result = n1 * (t2 / t1) - n2
        }
        return result / max
      case 'multiply':
        result = (n1 * n2) / (t1 * t2)
        return result
      case 'divide':
        result = (n1 / n2) * (t2 / t1)
        return result
    }
  }

  // 加减乘除的四个接口
  function add(a, b, digits) {
    return operation(a, b, digits, 'add')
  }
  function subtract(a, b, digits) {
    return operation(a, b, digits, 'subtract')
  }
  function multiply(a, b, digits) {
    return operation(a, b, digits, 'multiply')
  }
  function divide(a, b, digits) {
    return operation(a, b, digits, 'divide')
  }

  // exports
  return {
    add: add,
    subtract: subtract,
    multiply: multiply,
    divide: divide
  }
}())

/**
 * 小数四舍五入
 * @param {number | string} num 需要四舍五入的数值
 * @param {number} s 需要保留的小数位数
 * @returns {string}
 */
export function numberFixed(num: number | string, s: number) {
  const digit = Math.pow(10, s)
  const flag = (num >= 0 ? 0.5 : -0.5)
  let res = floatObj.add(floatObj.multiply(num, digit), flag)
  res = floatObj.divide(parseInt(res, 10), digit)
  return res + ''
}

/**
 * 缓存计算属性
 * @param {function} fn 处理逻辑的方法
 * @returns {result} 计算结果
 */
export function useComputed(fn: any) {
  const cache = new Map()

  function compare(args1: any, args2: any) {
    return (
      args1.length === args2.length && args1.every((v: any, i: number) => Object.is(v, args2[i]))
    )
  }

  function getCache(args: any) {
    const keys = [...cache.keys()]
    const key = keys.find(v => compare(v, args))
    if (key) {
      return cache.get(key)
    }
  }
  return function(...args: any) {
    const cacheResult = getCache(args)

    if (cacheResult) {
      return cacheResult.value
    }
    const result = computed(() => fn(...args))
    cache.set(args, result)

    return result.value
  }
}

/**
 * 等待函数
 * @date 2024-01-10
 * @param number ms // 等待的毫秒数
 */
export function sleep(ms) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms)
  })
}

export function findKeyByValue(obj, value) {
  for (const key in obj) {
    if (isArray(obj[key])) {
      const arr = obj[key].flat(Infinity)
      if (arr.indexOf(value) !== -1) return key
    } else if (isNumber(obj[key]) && obj[key] === value) {
      return key
    }
  }
  return null
}
