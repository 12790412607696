/**
 * 说明：考试/作业枚举
*/

import { PERIOD, SUBJECT } from './teachingMaterialEnums'

/** 上传学生得分情况-弹窗流程-前端自定义 **/
export enum STUDENT_UPLOAD_PROMPT {
  UPLOAD_KNOWLEDGE = 'UploadKnowledge', // 上传题目对应知识点
  UPLOADED_KNOWLEDGE = 'UploadedKnowledge', // 上传题目对应知识点后
  UPLOAD_SCORE = 'UploadScore', // 上传学生得分情况
  UPLOADED_SCORE = 'UploadedScore', // 上传学生得分情况后
  UPLOAD_INFO = 'UploadInfo', // 批量上传学生信息
  UPLOADED_INFO = 'UploadedInfo', // 批量上传学生信息之后
  PROCESSING = 'Processing' // 正在上传
}

/** 上传类型 **/
export enum TABLE_TYPE {
  STUDENT = 'Student', // 上传学生信息
  KNOWLEDGE_POINT = 'KnowledgePoint', // 上传题目对应知识点
  SCORE = 'Score', // 上传得分情况
  // ANSWERCARDPACK = 'AnswerCardPack' // 上传答题卡
}

/** 上传后获取处理状态 **/
export enum TABLE_PROCESS_STATUS {
  RUN = 'Run', // 正在处理
  SUCCESS = 'Success', // 成功
  FAIL = 'Fail', // 失败
  MD5ERR = 'Md5Err' // 也是失败，太久忘了为啥区分开了，不懂问后端
}

/** 考试类型 **/
export enum EXAM_TYPE {
  GRADE_TYPE = 'GradeType', // 年级测试
  CLASS_TYPE = 'ClassType' // 班级测试
}
export const examTypeList: labelValueList<EXAM_TYPE> = [
  { label: '年级测试', value: EXAM_TYPE.GRADE_TYPE },
  { label: '班级测试', value: EXAM_TYPE.CLASS_TYPE }
]

/** 考试详情-成绩排序，学情报告-知识点 **/
export enum EXAM_SORT {
  DEFAULT = 'Default', // 默认
  SCORE_ASC = 'ScoreAsc', // 升序
  SCORE_DESC = 'ScoreDesc' // 降序
}
// 学情报告-知识点
export const studyReportSortList: labelValueList<EXAM_SORT> = [
  { label: '得分率从低到高', value: EXAM_SORT.SCORE_ASC },
  { label: '得分率从高到低', value: EXAM_SORT.SCORE_DESC }
]

/** 选择题切片 **/
export enum MULTIPLE_CHOICE_SORT_TYPE {
  CROSS = 'Cross', // 横向
  VERTICAL = 'Vertical' // 纵向
}

/** 生成组卷尺寸 **/
export enum PAPER_SIZE {
  A3 = 'A3',
  A4 = 'A4',
  B4 = 'B4',
  B5 = 'B5',
  NONE = 'None'
}

/** 生成组卷文件类型 **/
export enum TEST_PAPER_FORMAT {
  DOCX = 'Docx',
  // DOC = 'Doc',
  PDF = 'Pdf'
}

/** 组卷类型 **/
export enum GROUP_TESTPAPER_TYPE {
  WORK = 'Work', // 作业
  CLASS_EXAM = 'ClassExam', // 班级测试
  WEEK_EXAM = 'WeekExam', // 周测
  SCHOOL_EXAM = 'SchoolExam' // 校考
}
export const groupTestpaperTypeList = [
  { label: '作业', value: GROUP_TESTPAPER_TYPE.WORK, type: EXAM_TYPE.CLASS_TYPE },
  { label: '班级测试', value: GROUP_TESTPAPER_TYPE.CLASS_EXAM, type: EXAM_TYPE.CLASS_TYPE },
  { label: '周测', value: GROUP_TESTPAPER_TYPE.WEEK_EXAM, type: EXAM_TYPE.GRADE_TYPE },
  { label: '校考', value: GROUP_TESTPAPER_TYPE.SCHOOL_EXAM, type: EXAM_TYPE.GRADE_TYPE }
]

/** 组卷 适配精度 **/
export enum Create_Ai_UserType {
  None = 'None',
  Group = 'Group', // 班级
  Student = 'Student' // 学生个人
}
export const createUserTypeList: labelValueList<Create_Ai_UserType> = [
  { label: '按班级', value: Create_Ai_UserType.Group },
  { label: '按个人', value: Create_Ai_UserType.Student }
]

/** Ai组卷入口-前端自定义 **/
export enum GROUP_QUESTION_ENTRANCE {
  CHAPTER = 'Chapter',
  KNOWLEDGE = 'Knowledge'
}

/** 批改类型 **/
export enum CORRECT_TYPE {
  NONE = 'None', // 不批改
  MERGE = 'Merge', // 题卡合一
  SEPARATE = 'Separate' // 题卡分离
}

/** 组题方式 **/
export enum CREATE_TYPE {
  MANUAL = 'Manual', // 手动
  AI = 'Ai' // 自动
}

/** 组卷计分表格 **/
export enum POINT_TABLE_STATUS {
  CLOSE = 'Close', // 关闭
  OPEN = 'Open', // 打开
}

/** 切片位置 **/
export enum SLICE_SIDE {
  LEFT = 'L', // 左侧
  RIGHT = 'R', // 右侧
}

/** 切片标注 **/
export enum MARKSLICE {
  MARK_START = 'markStart', // 开始标注
  MARK_AGAIN = 'markAgain' // 重新标注
}

/** 班级等级 **/
export enum CLASS_LEVEL {
  NONE = 'None', // 未指定
  NORMAL = 'Normal', // 平行
  BETTER = 'Better', // 实验
  TOP = 'Top' // 尖子
}
export const classLevelList = [
  { label: '尖子班', value: CLASS_LEVEL.TOP },
  { label: '实验班', value: CLASS_LEVEL.BETTER },
  { label: '平行班', value: CLASS_LEVEL.NORMAL }
]
// 自定义将班级类型按等级区分（作业部分需要）
export const levelClassificationList = [
  {
    level: 1,
    list: [CLASS_LEVEL.BETTER, CLASS_LEVEL.TOP]
  },
  {
    level: 2,
    list: [CLASS_LEVEL.NONE, CLASS_LEVEL.NORMAL]
  }
]

/** 考试、作业列表状态 **/
export enum StudentWorkStatus // 自己生成的考试的状态
{
  Creating = 'Creating', // 创建中
  CreatedFail = 'CreatedFail', // 创建失败
  Created = 'Created', // 创建完成
  NotUpload = 'NotUpload', // 未上传
  Upload = 'Upload', // 上传完成待批改
  Uncomplete = 'Uncomplete', // 未批改完成
  Complete = 'Complete' // 完成
}
export const studentWorkStatusList = [
  { label: '文件生成中', value: StudentWorkStatus.Creating },
  { label: '文件生成失败', value: StudentWorkStatus.CreatedFail },
  { label: '文件已生成', value: StudentWorkStatus.Created },
  { label: '待上传扫描件', value: StudentWorkStatus.NotUpload },
  { label: '待批改', value: StudentWorkStatus.Upload },
  { label: '未全部批改完', value: StudentWorkStatus.Uncomplete },
  { label: '已批改', value: StudentWorkStatus.Complete }
]

/** 个人作业 状态 **/
export enum IndividualWorkStatus {
  None = 'None', // 0
  Creating = 'Creating', // 1 创建中
  CreatedFail = 'CreatedFail', // 2 创建失败
  Created = 'Created', // 3 创建完成,待确认
  FileCreating = 'FileCreating', // 4 文件生成中
  FileCreatedFail = 'FileCreatedFail', // 5 文件生成失败
  FileCreated = 'FileCreated', // 6 文件已生成
  NotUpload = 'NotUpload', // 7 未上传，待上传扫描件
  Upload = 'Upload', // 8 上传完成待批改
  Uncomplete = 'Uncomplete', // 9未批改完成
  Complete = 'Complete', // 10 完成
}
export const individualWorkStatusList = [
  { label: '创建中', value: IndividualWorkStatus.Creating },
  { label: '创建失败', value: IndividualWorkStatus.CreatedFail },
  { label: '待确认', value: IndividualWorkStatus.Created },
  { label: '文件生成中', value: IndividualWorkStatus.FileCreating },
  { label: '文件生成失败', value: IndividualWorkStatus.FileCreatedFail },
  { label: '文件已生成', value: IndividualWorkStatus.FileCreated },
  { label: '待上传扫描件', value: IndividualWorkStatus.NotUpload },
  { label: '待批改', value: IndividualWorkStatus.Upload },
  { label: '未全部批改完', value: IndividualWorkStatus.Uncomplete },
  { label: '已批改', value: IndividualWorkStatus.Complete }
]

/** 删除题目原因 **/
export enum REMOVE_REASON_TYPE {
  OTHER = 'Other', // 其他，需手动填写
  DIFFICULTY = 'Difficulty', // 难度过大
  SUPERCLASS = 'Superclass', // 超纲
  MANY = 'Many' // 题量过多
}

/** Ai组题场景 **/
export enum ExaminationPaperScene {
  None = 'None',
  NewLesson = 'NewLesson', // 新知授课
  ChapterReview = 'ChapterReview', // 章节复习
  MidtermReview = 'MidtermReview', // 期中复习
  TermReview = 'TermReview', // 期末复习
  Peiyou = 'Peiyou', // 培优
  FillVacancies = 'FillVacancies', // 补缺
  FirstReview = 'FirstReview', // 一轮复习
  SecondReview = 'SecondReview' // 二轮复习
}

export const aiCreateScene = {
  [GROUP_QUESTION_ENTRANCE.CHAPTER]: [
    { label: ExaminationPaperScene.NewLesson, value: '新知授课' },
    { label: ExaminationPaperScene.ChapterReview, value: '章节复习' },
    // { label: ExaminationPaperScene.MidtermReview, value: '期中复习' },
    // { label: ExaminationPaperScene.TermReview, value: '期末复习' },
    { label: ExaminationPaperScene.TermReview, value: '期中/期末复习' }, // 前两者合并
    { label: ExaminationPaperScene.FillVacancies, value: '补缺' }
  ],
  [GROUP_QUESTION_ENTRANCE.KNOWLEDGE]: [
    { label: ExaminationPaperScene.FillVacancies, value: '补缺' },
    { label: ExaminationPaperScene.Peiyou, value: '培优' },
    { label: ExaminationPaperScene.FirstReview, value: '一轮复习' },
    { label: ExaminationPaperScene.SecondReview, value: '二轮复习' }
  ]
}

/** AI组题自定义题量 默认题量 **/
export const aiCustomQuesNum = {
  [SUBJECT.MATH]: {
    [PERIOD.SECONDARY_SCHOOL]: {
      'default': [ // 隐藏 判断 无 实验、作图、综合、科普阅读
        { label: '单选', value: 'choiceNumber', default: 10 }, // 单选
        { label: '多选', value: 'multipleChoiceNumber', default: 0 }, // 多选
        { label: '填空', value: 'fillInTheBlankNumber', default: 5 }, // 填空
        { label: '计算', value: 'calculationNumber', default: 0 }, // 计算
        { label: '解答', value: 'applicationNumber', default: 4 } // 解答
      ],
      [ExaminationPaperScene.TermReview]: [ // 隐藏 判断 无 实验、作图、综合、科普阅读
        { label: '单选', value: 'choiceNumber', default: 10 }, // 单选
        { label: '多选', value: 'multipleChoiceNumber', default: 0 }, // 多选
        { label: '填空', value: 'fillInTheBlankNumber', default: 5 }, // 填空
        { label: '计算', value: 'calculationNumber', default: 0 }, // 计算
        { label: '解答', value: 'applicationNumber', default: 8 } // 解答
      ]
    },
    [PERIOD.HIGH_SCHOOL]: {
      'default': [ // 无 实验、作图、综合、科普阅读
        { label: '单选', value: 'choiceNumber', default: 8 }, // 单选
        { label: '多选', value: 'multipleChoiceNumber', default: 3 }, // 多选
        { label: '填空', value: 'fillInTheBlankNumber', default: 3 }, // 填空
        { label: '计算', value: 'calculationNumber', default: 0 }, // 计算
        { label: '解答', value: 'applicationNumber', default: 5 }, // 解答
        { label: '判断', value: 'tOrFNumber', default: 0 } // 判断
      ]
    }
  },
  [SUBJECT.PHYSICS]: {
    [PERIOD.SECONDARY_SCHOOL]: {
      'default': [ // 隐藏 判断
        { label: '单选', value: 'choiceNumber', default: 6 }, // 单选
        { label: '多选', value: 'multipleChoiceNumber', default: 3 }, // 多选
        { label: '填空', value: 'fillInTheBlankNumber', default: 5 }, // 填空
        { label: '计算', value: 'calculationNumber', default: 0 }, // 计算
        { label: '解答', value: 'applicationNumber', default: 3 }, // 解答
        { label: '实验', value: 'experimentNumber', default: 2 }, // 实验
        { label: '作图', value: 'drawingNumber', default: 2 }, // 作图
        { label: '综合', value: 'comprehensiveNumber', default: 0 }, // 综合
        { label: '科普阅读', value: 'popularizationNumber', default: 0 } // 科普阅读
      ]
    },
    [PERIOD.HIGH_SCHOOL]: {
      'default': [ // 隐藏 计算、判断 无 综合、科普阅读
        { label: '单选', value: 'choiceNumber', default: 7 }, // 单选
        { label: '多选', value: 'multipleChoiceNumber', default: 3 }, // 多选
        { label: '填空', value: 'fillInTheBlankNumber', default: 0 }, // 填空
        { label: '解答', value: 'applicationNumber', default: 3 }, // 解答
        { label: '实验', value: 'experimentNumber', default: 2 } // 实验
      ]
    }
  },
  'default': { // 默认学段
    'default': [ // 默认科目
      { label: '单选', value: 'choiceNumber', default: 10 }, // 单选
      { label: '多选', value: 'multipleChoiceNumber', default: 2 }, // 多选
      { label: '填空', value: 'fillInTheBlankNumber', default: 2 }, // 填空
      { label: '解答', value: 'applicationNumber', default: 3 } // 解答
    ]
  }
}
export function getAiCustomQuesNum(subject: string, period: string, scene: string | null): {label: string, value: string, default: number}[] {
  const defaultRes = aiCustomQuesNum['default']['default']
  const customBysubject = (aiCustomQuesNum as any)[subject]
  if (!customBysubject) return defaultRes
  const customByPeriod = (customBysubject as any)[period]
  if (!customByPeriod) return defaultRes
  if (scene != null && Object.keys(customByPeriod).includes(scene)) {
    return (customByPeriod as any)[scene] || defaultRes
  } else {
    return customByPeriod['default']
  }
}
/** 学情报告--维度 **/
export enum DATE_TYPE {
  NONE = 'None', // 无/全部
  SECONDS = 'Seconds', // 秒
  MINUTE = 'Minute', // 分
  HOUR = 'Hour', // 时
  DAY = 'Day', // 日
  WEEK = 'Week', // 周
  MONTH = 'Month', // 月
  YEAR = 'Year' // 年
}

export const dimensionList = [
  { label: '全部', value: DATE_TYPE.NONE },
  { label: '年', value: DATE_TYPE.YEAR },
  { label: '月', value: DATE_TYPE.MONTH },
  { label: '周', value: DATE_TYPE.WEEK }
]

/** 作业批改人数--批改方式 **/
export enum STUDENT_WORK_CHECK_TYPE {
  DEFAULT = 'Default', // 默认
  APPOINT = 'Appoint', // 指定
  RANDOM = 'Random' // 随机
}

export const studentWorkCheckTypeList = [
  { label: '随机抽取', value: STUDENT_WORK_CHECK_TYPE.RANDOM },
  { label: '指定抽取', value: STUDENT_WORK_CHECK_TYPE.APPOINT }
]
