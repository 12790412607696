/**
 * 说明：打印枚举
*/

/** 打印类型 **/
export enum PRINT_TYPE {
  EXAM = 'Exam',
  HOMEWORK = 'Homework'
}

/** 打印方式 **/
export enum PRINT_METHOD {
  OTHER = 'Other', // 其他
  IMMEDIATE = 'Immediate', // 立即打印
  HOLDPRINT = 'HoldPrint' // 队列打印
}
export const printMethodOptions = [
  { label: '立即打印', value: PRINT_METHOD.IMMEDIATE },
  { label: '队列打印（稍后打印）', value: PRINT_METHOD.HOLDPRINT }
]

/** 打印任务-当前状态 **/
export enum PRINT_JOB_STATUS {
  NONE = 'None', // 无状态
  CREATED = 'Created', // 已建立
  SEND = 'Send', // 已发送
  FAILED = 'Failed', // 失败
  PRINT = 'Print', // 打印
  FINISH = 'Finish' // 完成
}
export const printJobStatusList = [
  { label: '未知', value: PRINT_JOB_STATUS.NONE },
  { label: '等待打印', value: PRINT_JOB_STATUS.CREATED },
  { label: '等待打印', value: PRINT_JOB_STATUS.SEND },
  { label: '打印失败', value: PRINT_JOB_STATUS.FAILED },
  { label: '正在打印', value: PRINT_JOB_STATUS.PRINT },
  { label: '打印完成', value: PRINT_JOB_STATUS.FINISH }
]

/** 打印任务-类型 **/
export enum PRINT_JOB_TYPE {
  NONE = 'None', // 无状态
  EXAMINATION_PAPER = 'ExaminationPaper', // 组卷
  EXAM = 'Exam', // 考试
  WORK = 'Work', // 作业
  DATA_TABLE = 'DataTable' // 数据表格
}
export const printJobTypeList = [
  { label: '未知', value: PRINT_JOB_TYPE.NONE },
  { label: '组卷', value: PRINT_JOB_TYPE.EXAMINATION_PAPER },
  { label: '考试', value: PRINT_JOB_TYPE.EXAM },
  { label: '作业', value: PRINT_JOB_TYPE.WORK },
  { label: '表格', value: PRINT_JOB_TYPE.DATA_TABLE }
]
