/**
 * 说明：公共枚举
*/

/** 操作类型 **/
export enum OPERATION_TYPE {
  ADD = 'Add',
  REPLACE = 'Replace',
  REMOVE = 'Remove',
  SORT = 'Sort'
}

/** 页面名称 **/
export enum PAGE_NAME {
  LEARNINGDESIGN = 'prepareLessons-chapter-chapterId-lesson-lessonId-learningdesign',
  LEARNING_DESIGN_I = 'prepareLessons-chapter-chapterId-lesson-lessonId-learningDesignI',
  LEARNING_DESIGN_II = 'prepareLessons-chapter-chapterId-lesson-lessonId-learningDesignII',
  LEARNING_DESIGN_III = 'prepareLessons-chapter-chapterId-lesson-lessonId-learningDesignIII',
  LEARNING_SITUATIONS = 'prepareLessons-chapter-chapterId-learningSituation',
  UNIT_EXAM = 'prepareLessons-chapter-chapterId-unitExam',
  EXAM_DETAILS = 'index-teachingManagement-exam-examId',
  GROUP = 'index-teachingManagement-group',
  CLASS_SCORE_CHART = 'index-teachingManagement-group-id-classScoreChart',
  TEACHING_SITUATION = 'index-teachingManagement-teacher-id-viewTeachingSituation',
  ACCOUNT_SETTINGS = 'index-settings-accountSettings'
}

/** 缺省类型 **/
export enum EMPTY_TYPE {
  NODATA = 'NoData',
  NOAUTH = 'NoAuth',
  NOGRAPH = 'NoGraph'
}

/** 错误码 **/
export const errorCode2Message = {
  1001: '成功',
  1002: '失败',
  1003: '网络异常',
  1004: '认证失败',
  1005: '不一致',
  1006: '模版知识点为空',
  1007: '参数错误',
  1008: '完成',
  1009: '参数类型错误',

  1403: '权限不足',
  1404: '资源未找到',
  1409: '冲突',
  1410: '状态错误',

  1900: '状态错误',
  1901: '状态错误',
  1902: '学段错误',
  1903: 'AI生成中',
  1904: 'AI生成失败',
  1905: '班级等级跨级',
  1906: '不能下载',
  1907: '亚马逊存储服务异常',
  1908: '文档生成中',
  1909: '文档生成失败，重新生成中',
  1910: '类型错误',
  1911: '批改类型错误',
  1912: '班级或年级未找到',
  1913: '学科错误',
  1914: '知识点为空',
  1915: '知识点超纲',
  1916: '知识点超纲',
  1917: '生成中',
  1918: '生成失败',
  1919: '生成成功',
  1920: '未打印记录',
  1921: '打印过',
  1922: '无打印任务',
  1923: '打印机类型错误',
  1924: '知识点为空'
}

/** 预览iframe-模板链接 **/
// export const iframeUrl = 'http://localhost:5173/'
// export const iframeUrl = 'https://pdf-template-dev.s3.cn-northwest-1.amazonaws.com.cn/index.html' // 测试
export const iframeUrl = 'https://pdf-template.s3.cn-northwest-1.amazonaws.com.cn/index.html' // 生产

// 登录cookie
export const authCookieName = 'sub'
