import { ElMessage, ElMessageBox, ElNotification } from 'element-plus'

const { confirm, alert, prompt } = ElMessageBox

export const $message = ElMessage

export const $confirm = confirm

export const $alert = alert

export const $prompt = prompt

export const $notify = ElNotification
