// 获取个人作业列表
export function getWorkList(requestData: individualWorksTypes.WorkListReq, key = 'getWorkList') {
  const url = '/individualworks/list'
  return $request<individualWorksTypes.WorkListRes>(key, { url }, { method: 'post', body: requestData })
}

// 删除个人作业
export function delIndividualWork(requestData: number, key = 'delindividualWork') {
  const url = `/individualworks/${requestData}` // requestData 作业id
  return $request(key, { url }, { method: 'delete' })
}

// 编辑个人作业
export function editIndividualWork(requestData: individualWorksTypes.EditWorkReq, key = 'editIndividualWork') {
  const url = `/individualworks/${requestData.id}`
  return $request(key, { url }, { method: 'PATCH', body: requestData.data })
}

// 个人作业详情
export function individualWorkDetail(requestData: individualWorksTypes.WorkDetailReq, key = 'individualWorkDetail') {
  const url = `/individualworks/${requestData.id}/studentscores`
  return $request<individualWorksTypes.WorkDetailRes>(key, { url }, { method: 'post', body: requestData.pageToken, query: { q: requestData.q }})
}

// 查看学生作业接口
export function viewIndividualWork(requestData: individualWorksTypes.ViewWorkReq, key = 'previewIndividualWork') {
  const url = `/individualworks/${requestData.workId}/students/${requestData.studentId}/scan`
  return $request<individualWorksTypes.ViewWorkRes>(key, { url }, { method: 'get', query: { page: requestData.page }})
}

// 获取个人作业学生
export function getIndividualWorkStudentList(requestData: number, key = 'getIndividualWorkStudentList') {
  const url = `/individualworks/${requestData}/students` // requestData 个人作业id
  return $request<individualWorksTypes.StudentList[]>(key, { url }, { method: 'get' })
}

// 获取学生个人作业选择题标准答案
export function getIndiWorkSelctStandardAnswer(requestData: individualWorksTypes.StandardSelectAnswerReq, key = 'getIndiWorkSelctStandardAnswer') {
  const url = `/individualworks/${requestData.id}/students/${requestData.studentId}/choice/answer`
  return $request<individualWorksTypes.StandardSelectAnswerRes>(key, { url }, { method: 'get' })
}

// 修改学生个人作业选择题标准答案
export function changeStandardSelectAnswer(requestData: individualWorksTypes.ChangeStandardSelectAnswerReq, key = 'changeStandardSelectAnswer') {
  const url = `/individualworks/${requestData.id}/students/${requestData.studentId}/choice/answer`
  return $request(key, { url }, { method: 'PATCH', body: requestData.questions })
}

// 批改页面获取个人作业扫描件和题目信息
export function getOneIndividualWork(requestData: individualWorksTypes.StudentIndividualWorksReq, key = 'getOneIndividualWork') {
  const url = `/individualworks/${requestData.id}/studentscans/pages/${requestData.page}`
  return $request<individualWorksTypes.StudentIndividualWorksRes>(key, { url }, { method: 'get', query: {
    deviceType: requestData.deviceType,
    size: requestData.size
  }})
}

// 修改学生题目分数
export function setIndividualWorkScore(requestData: individualWorksTypes.IndividualWorkScoreReq, key = 'setIndividualWorkScore') {
  const url = `/individualworks/${requestData.id}/questions/${requestData.qid}/students/${requestData.studentId}/score/${requestData.score}`
  return $request(key, { url }, { method: 'post' })
}

// 获取个人作业答案
export function getOneIndividualWorkAnswer(requestData: { workId: number, studentId: number }, key = 'getOneIndividualWorkAnswer') {
  const { workId, studentId } = requestData
  const url = `/individualworks/${workId}/students/${studentId}/answer`
  return $request<individualWorksTypes.IndividualWorkAnswer>(key, { url })
}

// 上传个人作业答案 暂时未使用。。。
export function uploadIndividualWorkAnswer(requestData: individualWorksTypes.UploadIndividualWorkAnswerReq, key = 'uploadIndividualWorkAnswer') {
  const { workId, studentId, ...body } = requestData
  const url = `/individualworks/${workId}/students/${studentId}/answer`
  return $request(key, { url }, { method: 'post', body })
}

// 下载个人作业
export function downloadIndividualWork(requestData: { id: number }, key = 'downloadIndividualWork') {
  const { id } = requestData
  const url = `/individualworks/${id}/file/download`
  return $request<individualWorksTypes.DownloadIndividualWorkRes>(key, { url })
}

// 获取个人作业是否打印过
export function getIndividualWorkRecord(id: number, key = 'getIndividualWorkRecord') {
  const url = `/individualworks/${id}/print/record`
  return $request<individualWorksTypes.PrintRecordInfo>(key, { url })
}

// 个人作业文件打印
export function individualWorkPrint(requestData: Omit<PrintModel, 'groupIds'>, key = 'printIndividualWork') {
  const url = `/individualworks/print`
  return $request<{ code: number }>(key, { url }, { method: 'post', body: requestData })
}

// 个人作业确认生成
export function confirmGenIndividualWork(requestData: individualWorksTypes.ConfirmGenIndividualWorkReq, key = 'confirmGenIndividualWork') {
  const { id, ...query } = requestData
  const url = `/individualworks/${id}/generate-pdf`
  return $request(key, { url }, { query })
}

// 个人作业确认生成任务重新执行
export function genIndividualWorkAgain(requestData: { id: number }, key = 'genIndividualWorkAgain') {
  const { id } = requestData
  const url = `/individualworks/${id}/reboot`
  return $request(key, { url })
}

// 获取学生单次个人作业图谱
export function getIndividualWorkPortait(requestData: individualWorksTypes.GetIndividualWorkPortaitReq, key = 'getIndividualWorkPortait') {
  const { workId, studentId } = requestData
  const url = `/graph/students/${studentId}/individual-works/${workId}/points`
  return $request<individualWorksTypes.GetIndividualWorkPortaitRes>(key, { url, isOther: true })
}

// 生成个人作业数据文档
export function genIndividualWorkDataFile(requestData: individualWorksTypes.GenIndividualWorkDataFileReq, key = 'genIndividualWorkDataFile') {
  const url = '/individualworks/file'
  return $request<{recordId: number}>(key, { url }, { method: 'post', body: requestData })
}

// 个人作业分片上传
export function uploadIndividualWorkChunks(requestData: individualWorksTypes.individualWorkChunksReq, key = 'uploadIndividualWorkChunks') {
  const { id, chunk, file } = requestData
  const url = `/individualworks/${id}/answercards/chunks/${chunk}`
  return $request(key, { url }, { method: 'post', body: file })
}

// 个人作业上传合并
export function unionIndividualWorkChunks(requestData: individualWorksTypes.individualWorkChunksUnionReq, key = 'unionIndividualWorkChunks') {
  const { id, formData } = requestData
  const url = `/individualworks/${id}/answercards/mergefile`
  return $request(key, { url }, { method: 'post', body: formData })
}

// 获取个人作业学生题目信息
export function getIndividualWorkStudentQuestions(requestData: individualWorksTypes.GetIndividualWorkStudentQuestionsReq, key = 'getIndividualWorkStudentQuestions') {
  const { id, studentId } = requestData
  const url = `/individualworks/${id}/students/${studentId}/questions`
  return $request<individualWorksTypes.GetIndividualWorkStudentQuestionsRes>(key, { url })
}

// 个人作业预览
export function previewIndividualWork(requestData: testPaperTypes.getPreviewFile, key = 'previewIndividualWork') {
  const { size } = requestData
  const url = `https://6k0lywf8pb.execute-api.cn-northwest-1.amazonaws.com.cn/dev/pdf-gen/paperSize/${size}`
  return $request(key, { url }, { method: 'post', body: requestData })
}

// 获取个人作业学生得分情况
export function getStudentPersonHomework(requestData: individualWorksTypes.getStudentPersonHomeworkReq, key = 'getStudentPersonHomework') {
  const { workId, studentId } = requestData
  const url = `/individualworks/${workId}/students/${studentId}/questionscore`
  return $request<individualWorksTypes.getStudentPersonHomeworkRes>(key, { url })
}
