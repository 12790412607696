/**
 * 说明：轮询任务枚举
*/

import * as examEnums from './examEnums'

const { TABLE_PROCESS_STATUS } = examEnums

/** ================================================ 任务状态--start ================================================ **/
/** 任务类型 **/
export enum TASK_TYPE {
  EXAMINATION_PAPER_AI = 'ExaminationPaperAi', // AI组卷
  EXAMINATION_PAPER_FILE = 'ExaminationPaperFile', // 组卷文件生成
  EXAM_SCAN = 'ExamScan', // 考试扫描件上传
  WORK_SCAN = 'WorkScan', // 作业扫描件上传
  PRINT = 'Print', // 打印任务
  IndividualWorkAi = 'IndividualWorkAi', // 个人作业Ai组卷
  IndividualWorkFile = 'IndividualWorkFile' // 个人作业文件
}
export const taskTypeList = [
  { label: 'AI组卷', value: TASK_TYPE.EXAMINATION_PAPER_AI },
  { label: '下载文件生成', value: TASK_TYPE.EXAMINATION_PAPER_FILE },
  { label: '考试扫描识别', value: TASK_TYPE.EXAM_SCAN },
  { label: '作业扫描识别', value: TASK_TYPE.WORK_SCAN },
  { label: '打印任务', value: TASK_TYPE.PRINT },
  { label: '个人作业AI组卷', value: TASK_TYPE.IndividualWorkAi },
  { label: '个人作业文件', value: TASK_TYPE.IndividualWorkFile }
]
/** 当前状态 **/
export const taskStatusList = [
  { label: '正在进行', value: TABLE_PROCESS_STATUS.RUN },
  { label: '已完成', value: TABLE_PROCESS_STATUS.SUCCESS },
  { label: '失败/已取消', value: TABLE_PROCESS_STATUS.FAIL }
]
/** ================================================ 任务状态--end ================================================ **/
