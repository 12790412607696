import { defineStore } from 'pinia'

export const useAppStore = defineStore('app', () => {
  const mainContentLoading = ref(false)

  const updateLoading = (loading) => {
    mainContentLoading.value = loading
  }

  const requestKeyArr = ref<Set<string>>(new Set())

  const addToRequestKeyArr = (urlKey: string) => {
    requestKeyArr.value.add(urlKey)
  }

  const removeFromRequestKeyArr = (urlKey: string) => {
    requestKeyArr.value.delete(urlKey)
  }

  const isLeaveWin = ref(false) // 是否离开窗口
  const setLeaveWin = (state: boolean) => {
    isLeaveWin.value = state
  }

  return {
    mainContentLoading,
    updateLoading,
    requestKeyArr,
    addToRequestKeyArr,
    removeFromRequestKeyArr,
    isLeaveWin,
    setLeaveWin
  }
})
