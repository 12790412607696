// 判断对象是否拥有某个接口的某个属性
export function isOfType<T>(
  target: unknown,
  prop: keyof T
): target is T {
  return (target as T)[prop] !== undefined
}

/**
* 通过value去列表查找到对应的label
* @date 2022-08-12
* @param {Array} 需要查找label的数组
* @param {any} value 需要查找的值
* @returns {string} 找到的label
*/
export function getLabelWithValue(list: labelValueList<string | number>, value: string | number) {
  if (!(Object.prototype.toString.call(list) === '[object Array]')) return ''
  const obj = list.find(v => v.value === value)
  return obj && obj.label || ''
}
