// 获取学生画像
export function getStudentPortrait(requestData: portraitTypes.StudentPortraitReq, key = 'getClassPortrait') {
  const { studentId, subject } = requestData
  const url = `/graph/students/${studentId}/points`
  return $request<portraitTypes.StudentPortraitRes>(key, { url, isOther: true }, { query: { subject }})
}

// 获取学生某次考试的画像
export function getStudentPortraitWithExam(requestData: portraitTypes.StudentPortraitWithExamReq, key = 'getStudentPortraitWithExam') {
  const { studentId, examId } = requestData
  const url = `/graph/students/${studentId}/exams/${examId}/points`
  return $request<portraitTypes.StudentPortraitWithExamRes>(key, { url, isOther: true })
}

// 获取班级画像
export function getClassPortrait(requestData: portraitTypes.ClassPortraitReq, key = 'getClassPortrait') {
  const { groupId, subject } = requestData
  const url = `/graph/groups/${groupId}/points`
  return $request<portraitTypes.ClassPortraitRes>(key, { url, isOther: true }, { query: { subject }})
}

// 获取班级本次考试画像
export function getClassRecentPortrait(requestData: portraitTypes.ClassRecentPortraitReq, key = 'getClassRecentPortrait') {
  const { groupId, examId } = requestData
  const url = `/graph/groups/${groupId}/exams/${examId}/points`
  return $request<portraitTypes.ClassRecentPortraitRes>(key, { url, isOther: true })
}

// 获取学生单次作业的图谱
export function getStudentHomeworkPortrait(requestData: portraitTypes.StudentHomeworkPortraitReq, key = 'getStudentHomeworkPortrait') {
  const url = `/graph/students/${requestData.studentId}/works/${requestData.workId}/points`
  return $request<portraitTypes.StudentHomeworkPortraitRes>(key, { url, isOther: true }, { method: 'get' })
}

export function getClassHomeworkPortrait(requestData: portraitTypes.ClassHomeworkPortraitReq, key = 'getClassHomeworkPortrait') {
  const url = `/graph/groups/${requestData.groupId}/works/${requestData.workId}/points`
  return $request(key, { url, isOther: true }, { method: 'get' })
}

// 获取知识点历史得分记录
export function getPointHistory(requestData: portraitTypes.PointScoreHistoryReq, key = 'getPointHistory') {
  const url = '/pointscores/history'
  return $request<portraitTypes.PointScoreHistory>(key, { url }, { method: 'post', body: requestData })
}
