import { defineStore } from 'pinia'

export const useDatabaseStore = defineStore('database', () => {
  const dbTextbookInfo = ref<databaseTypes.textbookModel>({
    textbookId: 0,
    chapters: []
  })

  function setDbTextbookInfo(state: databaseTypes.textbookModel) {
    dbTextbookInfo.value = state
  }

  return {
    dbTextbookInfo,
    setDbTextbookInfo
  }
})

