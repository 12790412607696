import { defineStore } from 'pinia'

export const useCommonStore = defineStore('common', () => {
  const commonSearchValue = reactive<{text: string}>({
    text: ''
  })

  function setCommonSearchValue(value: string) {
    commonSearchValue.text = value
  }
  return {
    commonSearchValue,
    setCommonSearchValue
  }
})
