<template>
  <div class="app_main">
    <!-- 图标颜色渐变 -->
    <svg
      class="h-0"
    >
      <defs>
        <linearGradient
          id="gradient"
          x1="0%"
          y1="0%"
          x2="0%"
          y2="100%"
        >
          <stop
            offset="0%"
            style="stop-color:rgb(255, 196, 104);stop-opacity:1"
          />
          <stop
            offset="100%"
            style="stop-color:rgb(243, 133, 25);stop-opacity:1"
          />
        </linearGradient>
      </defs>
    </svg>

    <VitePwaManifest />

    <clientOnly>
      <NuxtLayout v-if="isLogin">
        <el-config-provider :locale="zhCn">
          <NuxtPage />
        </el-config-provider>
      </NuxtLayout>
      <AuthLoading v-else />
    </clientOnly>

    <!--    <PrintSuspension v-if="isRenderPrintStatus" />-->
  </div>
</template>

<script lang="ts" setup>
import { updateRemindPageTracking } from '~/plugins/vueDirective/eventTracking/pageTracking'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'

import '~/assets/iconfont/iconfont.css'
import '~/assets/css/katex.min.css'

const { authCookieName } = $enums
const route = useRoute()
const isLogin = ref(false)

onMounted(() => {
  // 重新获取教师信息
  $utils.mitt.on('updateTeacherInfo', () => {
    getTeacherUserInfo()
  })

  authLogin()
})

onUnmounted(() => {
  $utils.mitt.off('updateTeacherInfo')
})

if (process.client) {
  import('mathlive')
  import('~/assets/iconfont/iconfont')

  // window.onUsersnapLoad = function(api) {
  //   api.init()
  // }
  // const script = document.createElement('script')
  // script.defer = 1
  // script.src = 'https://widget.usersnap.com/global/load/45cb3c55-60f1-4a74-a7bf-62135e31920f?onload=onUsersnapLoad'
  // document.getElementsByTagName('head')[0].appendChild(script)
}

async function authLogin() {
  const auth = useCookie(authCookieName)

  if (auth.value) {
    isLogin.value = true
    if (route.path === '/') navigateTo('/home')

    nextTick(() => {
      appReady()
      startGetTaskStatus()
    })
  } else {
    if (route.path === '/' && route.query.code) {
      await $utils.getUserCookie(route.query.code as string)

      isLogin.value = true
      navigateTo('/home')

      nextTick(() => {
        appReady()
        startGetTaskStatus()
      })
    } else {
      $utils.oauthLogout()
    }
  }
}

function appReady() {
  getTeacherUserInfo()
  updateRemindPageTracking()
}

async function getTeacherUserInfo() {
  const { setTeacherUserInfo } = $stores.user.useUserStore()
  const { data, error } = await $apis.user.getUserInfo()

  if (error.value) return
  setTeacherUserInfo(data.value)
}

/** ========== 全局轮询查任务状态 start ========== **/
const noStatisticsRouteName: string[] = ['index-taskStatus'] // 不统计未读数据的地址名称
let timer: NodeJS.Timer
let isLeave: boolean // 是否离开本网站
let isRequestNow = false // 是否正在请求数据

const useTaskStatus = $stores.taskStatus.useTaskStatus()
const useAppStore = $stores.app.useAppStore()
const { TABLE_PROCESS_STATUS } = $enums

function startGetTaskStatus() {
  getTaskStatus()

  timer = setInterval(() => {
    if (!isRequestNow && !isLeave) {
      getTaskStatus()
    }
  }, 5000)
}

onMounted(() => {
  window.addEventListener('visibilitychange', visibility)
})

onUnmounted(() => {
  clearInterval(timer)
  window.removeEventListener('visibilitychange', visibility)
})

function visibility() {
  isLeave = document.hidden

  const { setLeaveWin } = useAppStore
  setLeaveWin(isLeave)
}

async function getTaskStatus() {
  isRequestNow = true
  const { data, error } = await $apis.user.getTaskStatus()
  isRequestNow = false

  if (error.value) {
    clearInterval(timer) // 接口报错停止轮询
    return
  }

  const resData = data.value
  const {
    storeTaskStatusData,
    setTaskStatusData,
    oldGenTotal: storeOldGenTotal,
    setOldGenTotal,
    newGenTotal: storeNewGenTotal,
    setNewGenTotal,
    oldPrintTotal: storeOldPrintTotal,
    setOldPrintTotal,
    newPrintTotal: storeNewPrintTotal,
    setNewPrintTotal
  } = useTaskStatus

  if (!storeTaskStatusData || !storeTaskStatusData.length) { // 初始化 or 特定页面只更新
    setTaskStatusData(resData as userTypes.TaskStatus[])
  } else {
    let oldGenTotal = storeOldGenTotal // 记录上一次已完成数据（除打印）
    let newGenTotal = storeNewGenTotal // 记录最新已完成数据（除打印）
    let oldPrintTotal = storeOldPrintTotal // 记录上一次已完成打印数据
    let newPrintTotal = storeNewPrintTotal // 记录最新已完成打印数据
    let printTaskRun = 0 // 打印正开始数量

    // 对比并统计已完成且未读数据
    resData?.forEach(newItem => {
      const curIndex = storeTaskStatusData.findIndex(oldItem => oldItem.id === newItem.id)

      if (curIndex !== -1) { // 存在于参照数据中
        if (storeTaskStatusData[curIndex].status === TABLE_PROCESS_STATUS.RUN && newItem.status !== TABLE_PROCESS_STATUS.RUN) {
          if (newItem.type === $enums.TASK_TYPE.PRINT && isRenderPrintStatus.value) {
            newPrintTotal += 1
          } else if (newItem.type !== $enums.TASK_TYPE.PRINT && isStatisticalGen.value) {
            newGenTotal += 1
          }
        }
      } else if (newItem.status !== TABLE_PROCESS_STATUS.RUN) { // 新数据产生变化
        if (newItem.type === $enums.TASK_TYPE.PRINT && isRenderPrintStatus.value) {
          newPrintTotal += 1
        } else if (newItem.type !== $enums.TASK_TYPE.PRINT && isStatisticalGen.value) {
          newGenTotal += 1
        }
      } else if (newItem.status === TABLE_PROCESS_STATUS.RUN && newItem.type === $enums.TASK_TYPE.PRINT && isRenderPrintStatus.value) { // 新的打印任务开始
        printTaskRun += 1
      }
    })

    if (printTaskRun) {
      ElNotification({
        title: '通知',
        dangerouslyUseHTMLString: true,
        message: `您有<span class="text-primary">${printTaskRun}</span>份新的任务正在开始打印中...`,
        type: 'warning'
      })
    }

    if (oldGenTotal !== newGenTotal) {
      oldGenTotal = newGenTotal

      ElNotification({
        title: '通知',
        dangerouslyUseHTMLString: true,
        message: `有任务状态更新啦，快前往<span class="text-primary">任务状态页面</span>查看吧！`,
        type: 'success'
      })
    }

    if (oldPrintTotal !== newPrintTotal) {
      oldPrintTotal = newPrintTotal

      ElNotification({
        title: '通知',
        dangerouslyUseHTMLString: true,
        message: `您有${newPrintTotal}份打印任务状态更新啦，请前往<span class="text-primary">打印机状态页面</span>领取吧！`,
        type: 'success'
      })
    }

    // 更新store状态
    setOldGenTotal(oldGenTotal)
    setNewGenTotal(newGenTotal)
    setOldPrintTotal(oldPrintTotal)
    setNewPrintTotal(newPrintTotal)
    setTaskStatusData(resData as userTypes.TaskStatus[]) // 更新参照数据
  }
}

// 是否统计未读数据
const isStatisticalGen = computed(() => !noStatisticsRouteName.includes(route.name as string))

// 监听路由地址
watch(
  () => route.name,
  (newName) => {
    const { setOldGenTotal, setNewGenTotal, setOldPrintTotal, setNewPrintTotal } = useTaskStatus

    // 重置
    if (noStatisticsRouteName.includes(newName as string)) {
      setOldGenTotal(0)
      setNewGenTotal(0)
    } else if (noRenderPrintStatus.includes(newName as string)) {
      setOldPrintTotal(0)
      setNewPrintTotal(0)
    }
  }
)
/** ========== 全局轮询查任务状态 end ========== **/

/** ========== 打印状态 start ========== **/
const noRenderPrintStatus = ['index-printStatus']
const isRenderPrintStatus = computed(() => !noRenderPrintStatus.includes(route.name as string))
/** ========== 打印状态 end ========== **/
</script>

