import * as common from './stores/common'
import * as user from './stores/user'
import * as textbook from './stores/textbook'
import * as database from './stores/database'
import * as app from './stores/app'
import * as testPaper from './stores/testPaper'
import * as group from './stores/group'
import * as taskStatus from './stores/taskStatus'

export const $stores = {
  user,
  textbook,
  database,
  app,
  testPaper,
  group,
  taskStatus,
  common
}
