export function switchTextbook(requestData: textbookTypes.SwitchTextbookReq, key = 'switchTextbook') {
  const url = `textbooks/period/{period}/subject/{subject}/edition/{edition}/grade/{grade}`
  return $request<textbookTypes.SwitchTextbookRes[]>(key, { url, req: requestData }, { method: 'get' })
}

export function getValidUrl(requestData: { fixedUrl: string }, key = 'getValidUrl') {
  const { fixedUrl } = requestData
  const url = `/files/url/${fixedUrl}`
  return $request(key, { url, req: requestData }, { method: 'get' })
}
