import { $utils } from '~/composables/utils'
const { setStorage, getStorage, removeStorage, isEmpty } = $utils

/**
 * 进入备课页面时把未发送请求的页面统计时间发送给后端
 * @date 2022-10-26
 * @returns {any}
 */
export async function updateRemindPageTracking() {
  const pageTracking: any = getStorage('pageTracking') || {}
  const keys = Reflect.ownKeys(pageTracking)

  for (const key of keys) {
    const [pageName, pageArg] = (key as string).split('?')
    const lastRemindTime = pageTracking[key] || 0
    const arg = pageArg ? JSON.parse(pageArg) : {}
    const data: any = {
      ...arg,
      'type': pageName.split(':')[1],
      'timeLength': lastRemindTime,
      'module': pageName.split(':')[0]
    }
    await $apis.computedDownload.putPageTracking(data)
  }
  removeStorage('pageTracking') // 不管成功与否都删除之前保留的数据
}

function getTotalTime(el: any, binding: any) {
  const pageName = binding.value
  const pageTracking = (getStorage('pageTracking') || {}) as any
  const pageArg = binding.arg ? `?${JSON.stringify(binding.arg)}` : ''
  const lastRemindTime = pageTracking[`${pageName}${pageArg}`] || 0
  if (!el.customData.lastMouseMoveTime) {
    return lastRemindTime
  }
  const curentTime = new Date().getTime() - el.customData.enterTime

  const totalTime = curentTime - el.customData.invalidTime + lastRemindTime
  return totalTime
}

function savePageTime(el: any, binding: any) {
  return function() {
    const pageTracking:any = getStorage('pageTracking') || {}
    const totalTime = getTotalTime(el, binding)
    const pageArg = binding.arg ? `?${JSON.stringify(binding.arg)}` : ''
    const pageName = binding.value
    pageTracking[`${pageName}${pageArg}`] = totalTime
    setStorage('pageTracking', pageTracking)
  }
}

const timeout = 180000 // 鼠标超过多长时间没动，则开始计时，则在鼠标下次动之前，这段时间不计入页面停留时间

const pageTrackingDirective = {
  mounted: function(el: any, binding: any) {
    setTimeout(() => { // 不用定时器useRoute可能获取不了路由上的参数
      const now = new Date().getTime()
      el.customData = {
        enterTime: now,
        lastMousePos: '',
        lastMouseMoveTime: 0,
        invalidTime: 0
      }

      el.customData.handler = savePageTime(el, binding)
      window.addEventListener('beforeunload', el.customData.handler)

      el.customData.mouseHandler = useThrottle(function(e) {
      // mousemove 鼠标一移动，就会触发事件
      // 获取鼠标最新的坐标
        const curPos = JSON.stringify({ x: e.pageX, y: e.pageY })
        if (curPos !== el.customData.lastMousePos) {
          const curTime = new Date().getTime()
          const interval = curTime - (el.customData.lastMouseMoveTime || el.customData.enterTime)
          const invalidTime = interval - timeout
          el.customData.invalidTime = (invalidTime < 0 ? 0 : invalidTime) + el.customData.invalidTime
          el.customData.lastMousePos = curPos
          el.customData.lastMouseMoveTime = curTime
        }
      }, 1000)
      document.addEventListener('mousemove', el.customData.mouseHandler)
    }, 0)
  },
  beforeUnmount: function(el: any, binding: any) {
    if (!el.customData) return
    window.removeEventListener('beforeunload', el.customData.handler)
    document.removeEventListener('mousemove', el.customData.mouseHandler)

    // 遍历请求参数，如果请求参数为空，则不发送请求
    for (const key in binding.arg) {
      if (Object.prototype.hasOwnProperty.call(binding.arg, key)) {
        if (isEmpty(binding.arg[key])) return
      }
    }

    const totalTime = getTotalTime(el, binding)

    const pageName = binding.value
    const data: any = {
      ...binding.arg,
      'type': pageName.split(':')[1],
      'timeLength': totalTime,
      'module': pageName.split(':')[0]
    }
    const pageTracking: any = getStorage('pageTracking') || {}
    $apis.computedDownload.putPageTracking(data).then((res) => {
      const pageArg = binding.arg ? `?${JSON.stringify(binding.arg)}` : ''
      const { error } = res
      if (error.value) {
        pageTracking[`${pageName}${pageArg}`] = totalTime
        setStorage('pageTracking', pageTracking)
        return
      }
      Reflect.deleteProperty(pageTracking, `${pageName}${pageArg}`)
      setStorage('pageTracking', pageTracking)
    })
  }
}

export default pageTrackingDirective
