// 计算下载模板样例文件次数
export function putComputedSampleFiles(requestData, key = 'putComputedSampleFiles') {
  const { sampleFilesId } = requestData
  const url = `/sampleFiles/${sampleFilesId}/download-record`
  return $request(key, { url }, { method: 'put' })
}

// 计算下载资料文件次数
export function putComputedmaterials(requestData, key = 'putComputedmaterials') {
  const { id } = requestData
  const url = `/materials/${id}/download-record`
  return $request(key, { url }, { method: 'put' })
}

// 页面停留时间
export function putPageTracking(requestData, key = 'putPageTracking') {
  const { module, ...body } = requestData
  const url = `/${module}/page/time`
  return $request(key, { url }, { method: 'put', body })
}
