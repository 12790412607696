// 根据年级获取班级列表
export function getGroups(requestData: groupTypes.GetGroupReq, key = 'getGroups') {
  const url = '/group/classes/list'
  return $request<groupTypes.GetGroupRes>(key, { url }, { method: 'post', body: requestData })
}

// 新增班级
export function addGroup(requestData: groupTypes.GroupInfo, key = 'addGroup') {
  const url = '/group/classes'
  return $request(key, { url }, { method: 'post', body: requestData })
}

// 根据id获取班级信息
export function getGroupInfo(requestData: Pick<groupTypes.GetGroupReq, 'groupId'>, key = 'getGroupInfo') {
  const { groupId } = requestData
  const url = `/group/classes/${groupId}`
  return $request<groupTypes.GroupInfo>(key, { url })
}

// 编辑班级信息
export function editGroup(requestData: groupTypes.GroupInfo, key = 'editGroupInfo') {
  const { id } = requestData
  const url = `/group/classes/${id}`
  return $request(key, { url }, { method: 'put', body: requestData })
}

// 删除班级
export function removeGroup(requestData: { id: number }, key = 'removeGroup') {
  const { id } = requestData
  const url = `/group/classes/${id}`
  return $request(key, { url }, { method: 'delete' })
}

// 获取所有年级
export function getGradeGroup(key = 'getGradeGroup') {
  const url = '/group/grades'
  return $request<groupTypes.GradeAndClassGroup[]>(key, { url })
}

// 根据年级id获取班级
export function getGradeAndClass(key = 'getClassGroupByGradeId') {
  const url = '/group/classes'
  return $request<groupTypes.GradeAndClassGroup[]>(key, { url })
}

// 根据班级获取学生分数分布情况
export function getClassExamScore(requestData: groupTypes.getClassExamScoreReq, key = 'getClassExamScore') {
  const { id, subject } = requestData
  const url = `/group/${id}/subjects/${subject}/score`
  return $request<groupTypes.getClassExamScoreRes>(key, { url })
}

// 获取学科老师及班级信息
export function getClassTeacherInfo(requestData: Pick<groupTypes.LearnResultsParams, 'groupId' | 'subject'>, key = 'getClassTeacherInfo') {
  const { groupId, subject } = requestData
  const url = `/group/${groupId}/subjects/${subject}/teacher`
  return $request<Omit<groupTypes.getClassExamScoreRes, 'subject' | 'data'>>(key, { url })
}

// 学情报告--考试分析
export function getExamAnalysis(requestData: groupTypes.LearnResultsParams, key = 'getExamAnalysis') {
  const { groupId, subject, ...query } = requestData
  const url = `/learnresults/groups/${groupId}/subjects/${subject}/gradeexams/score`
  return $request<ExamAnalysisChartModel[]>(key, { url }, { query })
}

// 学情报告--获取题目难度记录
export function getQuestionDifficulty(requestData: groupTypes.LearnResultsParams, key = 'getQuestionDifficulty') {
  const { groupId, subject, ...query } = requestData
  const url = `/learnresults/groups/${groupId}/subjects/${subject}/questions/difficulty`
  return $request<QuestionDifficultyChartModel[]>(key, { url }, { query })
}

// 学情报告--获取知识点统计
export function getKnowledgeStatistic(requestData: groupTypes.LearnResultsParams, key = 'getKnowledgeStatistic') {
  const { groupId, subject, ...query } = requestData
  const url = `/learnresults/groups/${groupId}/subjects/${subject}/knowledgepoints/total`
  return $request<KnowledgeStatisticChartModel>(key, { url }, { query })
}

// 学情报告--获取作业和考试记录
export function getHomeworkAndExamReport(requestData: groupTypes.LearnResultsParams, key = 'getHomeworkAndExamReport') {
  const { groupId, subject, ...query } = requestData
  const url = `/learnresults/groups/${groupId}/subjects/${subject}/scores`
  return $request<HomeworkAndExamChartModel[]>(key, { url }, { query })
}

// 学情报告--获取学生知识点列表
export function getGroupKnowledgePoints(requestData: groupTypes.LearnResultsParams, key = 'getGroupKnowledgePoints') {
  const { groupId, subject, ...body } = requestData
  const url = `/learnresults/groups/${groupId}/subjects/${subject}/knowledgepoints/list`
  return $request<KnowlegePointsChartModel>(key, { url }, { method: 'post', body })
}

// 生成班级学情报告
export function genClassStudyReport(requestData: groupTypes.GenClassStudyReportReq, key = 'genClassStudyReport') {
  const { groupId, subject, ...body } = requestData
  const url = `/learnresults/groups/${groupId}/subjects/${subject}/file`
  return $request<{ recordId: number }>(key, { url }, { method: 'post', body })
}

export function getStudentByClassGroupId(requestData: number, key = 'getStudentByClassGroupId') {
  const url = `/group/${requestData}/students`
  return $request<groupTypes.StudentList[]>(key, { url }, { method: 'get' })
}
