// 获取打印机信息
export function getPrinter(key = 'getPrinter') {
  const url = '/printers'
  return $request<printerTypes.PrinterModule[]>(key, { url })
}

// 编辑打印机信息
export function editPrinter(requestData: printerTypes.PrinterModule[], key = 'editPrinter') {
  const url = '/printers'
  return $request<{ code: number }>(key, { url }, { method: 'PATCH', body: requestData })
}

// 获取考试的班级
export function getMergeExamGroup(requestData: { examId: number }, key = 'getMergeExamGroup') {
  const { examId } = requestData
  const url = `/exams/${examId}/classes`
  return $request<printerTypes.GetMergeExamGroup[]>(key, { url })
}

// 获取考试的班级，带有权限筛选，用于表格打印
export function getExamSheetClasses(requestData: number, key = 'getExamSheetClasses') {
  const url = `exams/${requestData}/teachclasses`
  return $request<printerTypes.GetMergeExamGroup[]>(key, { url })
}

// 考试打印
export function examPrint(requestData: PrintModel, key = 'examPrint') {
  const url = '/exams/print'
  return $request<{ code: number }>(key, { url }, { method: 'post', body: requestData })
}

// 作业打印
export function homeworkPrint(requestData: Omit<PrintModel, 'groupIds'>, key = 'homeworkPrint') {
  const url = '/works/print'
  return $request<{ code: number }>(key, { url }, { method: 'post', body: requestData })
}

// 获取表格文件生成状态
export function getSheetFileGenStatus(requestData: number, key = 'getFileGenerateStatus') {
  const url = `/records/createdocument/${requestData}/status`
  return $request<printerTypes.SheetFileStatus>(key, { url })
}

// 获取表格文件下载地址
export function getSheetFileDownloadUrl(requestData: number, key = 'getFileDownloadUrl') {
  const url = `/records/createdocument/${requestData}/download`
  return $request<printerTypes.SheetFileUrlRes>(key, { url }, { method: 'post' })
}

// 打印表格文件
export function printSheetFile(requestData: printerTypes.PrintSheetFileReq, key = 'printSheetFile') {
  const url = `/records/createdocument/${requestData.id}/print`
  return $request<Pick<printerTypes.PrintRecordInfo, 'code'>>(key, { url }, { method: 'post', body: requestData.print })
}

// 获取打印机类型
export function getPrintersModel(key = 'getPrinterModel') {
  const url = '/printers/model/list'
  return $request(key, { url })
}

// 获取作业是否打印过
export function getHomeworksPrintRecord(id: number, key = 'getHomeworksPrintRecord') {
  const url = `/works/${id}/print/record`
  return $request<printerTypes.PrintRecordInfo>(key, { url })
}

// 获取考试是否打印过
export function getExamsPrintRecord(requestData: printerTypes.GetExamsPrintRecordReq, key = 'getExamsPrintRecord') {
  const { id, groupIds } = requestData
  const url = `/exams/${id}/print/record`
  return $request<printerTypes.PrintRecordInfo>(key, { url }, { query: { groupId: groupIds }})
}

// 获取指定打印机任务列表
export function getPrinterTasks(requestData: printerTypes.GetPrintTaskReq, key = 'getPrinterTasks') {
  const url = '/printtasks/list'
  return $request<printerTypes.GetPrintTaskRes>(key, { url }, { method: 'post', body: requestData })
}

// 获取指定打印机当前状态
export function getPrinterStatus(requestData: { id: number }, key = 'getPrinterStatus') {
  const { id } = requestData
  const url = `/printers/${id}/status`
  return $request<printerTypes.PrintStauts>(key, { url })
}
