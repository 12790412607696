// 查询组卷题型题目个数
export function getTestPaperTotal(requestData: testPaperTypes.GetTestPaper, key = 'getTestPaperTotal') {
  const { period, subject } = requestData
  const url = `/examinationpaper/period/${period}/subject/${subject}/question/total`
  return $request<testPaperTypes.TestPaperInfo>(key, { url })
}

// 获取组卷的信息
export function getTestPaperInfo(requestData: testPaperTypes.GetTestPaper, key = 'getTestPaperInfo') {
  const { period, subject } = requestData
  const url = `/examinationpaper/period/${period}/subject/${subject}`
  return $request<testPaperTypes.getTestPaperInfoRes>(key, { url })
}

// 组卷增删排序批量
export function patchTestPaperInfo(requestData: testPaperTypes.TestPaperBatch, key = 'patchTestPaperInfo') {
  const { id, ...body } = requestData
  const url = `/examinationpaper/paper/${id}`
  return $request(key, { url }, { method: 'PATCH', body })
}

// 提交组卷
export function submitTestPaper(requestData: testPaperTypes.SubmitTestPaper, key = 'submitTestPaper') {
  const { id, ...body } = requestData
  const url = `/examinationpaper/paper/${id}`
  return $request<testPaperTypes.DownloadTestPaper>(key, { url }, { method: 'post', body })
}

// 根据章节或知识点获取题目的相关一级知识点
export function getOtherKnowledgeByQuestions(requestData: testPaperTypes.GetOtherKnowlegeReq, key = 'getOtherKnowledgeByQuestions') {
  const { ...body } = requestData
  const url = '/examinationpaper/paper/createbyai/knowledgepoint'
  return $request<databaseTypes.KnowledgePoints[]>(key, { url }, { method: 'post', body })
}

// AI生成组题
export function createByAI(requestData: testPaperTypes.CreateByAI, key = 'createByAI') {
  const { ...body } = requestData
  const url = '/examinationpaper/paper/createbyai'
  return $request(key, { url }, { method: 'post', body })
}

// 查AI组卷状态
export function checkGroupQuestionByAI(requestData: Pick<testPaperTypes.GetTestPaper, 'period' | 'subject'>, key = 'CheckGroupQuestionWithAI') {
  const { period, subject } = requestData
  const url = `/examinationPaper/period/${period}/subject/${subject}/questions/number`
  return $request<testPaperTypes.CheckGroupQuestionRes>(key, { url })
}

// 生成涂卡区域url
export function generateAnswerSheets(number: number, key = 'generateAnswerSheets') {
  // const url = 'https://jrm5lxxvkg.execute-api.cn-northwest-1.amazonaws.com.cn/dev/v1/answer-sheets/generate'
  const url = `examinationPaper/questioncount/${number}/area`
  return $request(key, { url }, { method: 'post' })
}

// 打印不批改试卷
export function printExamPaper(requestData: Omit<PrintModel, 'groupIds'>, key = 'printExamPaper') {
  const { id, ...body } = requestData
  const url = `/examinationpaper/paper/${id}/print`
  return $request<testPaperTypes.DownloadTestPaper>(key, { url }, { method: 'post', body })
}

// 下载不批改试卷
export function downloadExamPaper(requestData: { id: number }, key = 'downloadExamPaper') {
  const { id } = requestData
  const url = `/examinationpaper/paper/${id}/download`
  return $request<testPaperTypes.DownloadTestPaper>(key, { url }, { method: 'post' })
}

// 获取校本组卷记录列表
export function getSharePaperRecordList(requestData: testPaperTypes.getSharePaperRecordListReq, key = 'getSharePaperRecordList') {
  const body = requestData
  const url = `/examinationpaper/finish/list`
  return $request<testPaperTypes.getSharePaperRecordListRes>(key, { url }, { method: 'post', body })
}

// 获取组卷记录中的题目
export function getSharePaperRecordById(requestData: {id: number}, key = 'getSharePaperRecordList') {
  const { id } = requestData
  const url = `/examinationpaper/finish/${id}`
  return $request<testPaperTypes.getSharePaperRecordRes>(key, { url })
}

// 修改校本记录展示设置
export function setPaperRecordIsShare(requestData: project.IsShareModel, key = 'setHomeworkIsShare') {
  const { id, isShare, type } = requestData
  const url = `/${type}/${id}/pub?pub=${isShare}`
  return $request(key, { url }, { method: 'put' })
}

// 获取预览pdf
export function getPreviewPdfFile(requestData: testPaperTypes.getPreviewFile, key = 'getPreviewPdfFile') {
  const { size } = requestData
  const url = `https://6k0lywf8pb.execute-api.cn-northwest-1.amazonaws.com.cn/dev/pdf-gen/paperSize/${size}`
  return $request(key, { url }, { method: 'post', body: requestData })
}
