import { defineNuxtPlugin } from '#app'

function resetStore({ store }: { store: any }) {
  const initialState = useCloneDeep(store.$state)
  store.$reset = () => store.$patch(useCloneDeep(initialState))
}

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.$pinia.use(resetStore)
})
