/**
 * 说明：备课枚举
*/

/** ================================================ 模板样例类型--start ================================================ **/
export enum SAMPLE_TYPE {
  TEACHING_PLAN = 'TeachingPlan', // 教案
  LEARNING_PLAN = 'LearningPlan', // 学案
  COURSE_WARE = 'CourseWare', // 课件
  FILL_LIST = 'FillList', // 补缺清单
  CLASS_WORK = 'ClassWork', // 课时作业
  COMPRESSED_FILE = 'CompressedFile' // 压缩文件
}
export const templateFilterList: labelValueList<SAMPLE_TYPE> = [
  { label: '教案', value: SAMPLE_TYPE.TEACHING_PLAN },
  { label: '学案', value: SAMPLE_TYPE.LEARNING_PLAN },
  { label: '课件', value: SAMPLE_TYPE.COURSE_WARE }
]
export const myFilterList: labelValueList<SAMPLE_TYPE> = [
  // { label: '补缺清单', value: SAMPLE_TYPE.FILL_LIST },
  // { label: '课时作业', value: SAMPLE_TYPE.CLASS_WORK },
  { label: '压缩文件', value: SAMPLE_TYPE.COMPRESSED_FILE }
]
export const sampleFilesList = [...templateFilterList, ...myFilterList]
export const sampleFilesObj = {
  title: '类型',
  value: 'sampleFiles',
  items: sampleFilesList
}
/** ================================================ 模板样例类型--end ================================================ **/
