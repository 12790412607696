/**
 * 说明：教材信息枚举
*/

/** ================================================ 学段--start ================================================ **/
export enum PERIOD {
  PRIMARY_SCHOOL = 'PrimarySchool',
  SECONDARY_SCHOOL = 'SecondarySchool',
  HIGH_SCHOOL = 'HighSchool'
}
export const periodList = [
  { label: '小学', value: PERIOD.PRIMARY_SCHOOL },
  { label: '初中', value: PERIOD.SECONDARY_SCHOOL },
  { label: '高中', value: PERIOD.HIGH_SCHOOL }
]
export const periodObj = {
  title: '学段',
  value: 'periods',
  items: periodList
}
/** ================================================ 学段--end ================================================ **/

/** ================================================ 学科--start ================================================ **/
export enum SUBJECT {
  MATH = 'Math',
  CHINESE = 'Chinese',
  ENGLISH = 'English',
  PHYSICS = 'Physics',
  CHEMISTRY = 'Chemistry',
  BIOLOGY = 'Biology',
  HISTORY = 'History',
  GEOGRAPHY = 'Geography',
  POLITICS = 'Politics'
}
export const subjectList: labelValueList<SUBJECT> = [
  { label: '数学', value: SUBJECT.MATH },
  { label: '语文', value: SUBJECT.CHINESE },
  { label: '英语', value: SUBJECT.ENGLISH },
  { label: '物理', value: SUBJECT.PHYSICS },
  { label: '化学', value: SUBJECT.CHEMISTRY },
  { label: '生物', value: SUBJECT.BIOLOGY },
  { label: '历史', value: SUBJECT.HISTORY },
  { label: '地理', value: SUBJECT.GEOGRAPHY },
  { label: '政治', value: SUBJECT.POLITICS }
]
/** ================================================ 学科--end ================================================ **/

/** ================================================ 版本--start ================================================ **/
export enum EDITION {
  ALL = 'All', // 通用
  NONE = 'None', // 无教材版本
  PEPEditionA2019 = 'PEPEditionA2019', // 人教A版2019
  JIANGSU_EDITION = 'JiangSuEdition', // 苏教版
  BJNU_EDITION = 'BJNUEdition', // 北师大版2012
  ECNU_EDITION = 'ECNUEdition', // 华师版
  All = 'All', // 通用
  ECNUEdition = 'ECNUEdition', // 华师版
  PEP_EDITION = 'PEPEdition', // 人教版2012
  BJNUEdition2019 = 'BJNUEdition2019', // 北师大版2019
  PEPEditionB2019 = 'PEPEditionB2019', // 人教B版2019
  SEPEdition2020 = 'SEPEdition2020', // 沪教版2020
  JiangSuEdition2019 = 'JiangSuEdition2019', // 苏教版2019
  HNEPEdition2019 = 'HNEPEdition2019', // 湘教版2019
  PEPEditionA = 'PEPEditionA', // 人教A版
  PEPEditionB = 'PEPEditionB', // 人教B版
  HNEPEdition = 'HNEPEdition', // 湘教版
  SEPEdition = 'SEPEdition', // 沪教版
  None = 'None', // 无教材版本
  PEPEdition2019 = 'PEPEdition2019', // 人教版2019
  LKJEdition2019 = 'LKJEdition2019', // 鲁教版2019
  MSEEReview = 'MSEEReview', // 初中总复习
  CollegeEntranceReview = 'CollegeEntranceReview', // 高中总复习
  ZHE_JIANG_EDITION = 'ZheJiangEdition', // 浙教版
  PSPRESS = 'Pspress', // 苏科版(2012)
  PSPRESS_2024 = 'Pspress2024', // 苏科版(2024)
  BJNU_EDITION_2024 = 'BJNUEdition2024', // 北师大版2024
  PEP_EDITION_2024 = 'PEPEdition2024', // 人教版2024
  HUYUE_EDITION_2012 = 'HuYueEdition2012', // 沪粤版(2012)
  HUYUE_EDITION_2024 = 'HuYueEdition2024', // 沪粤版(2024)
  BEIJING_2012 = 'BeiJing2012', // 北京版(2012)
  BEIJING_2024 = 'BeiJing2024', // 北京版(2024)
  BJNU_EDITION_BEIJING_2012 = 'BJNUEditionBeiJing2012', // 北师大版(北京版)(2012)
  BJNU_EDITION_BEIJING_2024 = 'BJNUEditionBeiJing2024', // 北师大版(北京版)(2024)
}
export const editionList: labelValueList<EDITION> = [
  { label: '人教A版(2019)', value: EDITION.PEPEditionA2019 },
  { label: '苏教版', value: EDITION.JIANGSU_EDITION },
  { label: '北师大版', value: EDITION.BJNU_EDITION },
  { label: '华师版', value: EDITION.ECNU_EDITION },
  { label: '华师版', value: EDITION.ECNUEdition },
  { label: '人教版', value: EDITION.PEP_EDITION },
  { label: '北师大版(2019)', value: EDITION.BJNUEdition2019 },
  { label: '人教B版(2019)', value: EDITION.PEPEditionB2019 },
  { label: '沪教版(2020)', value: EDITION.SEPEdition2020 },
  { label: '苏教版(2019)', value: EDITION.JiangSuEdition2019 },
  { label: '湘教版(2019)', value: EDITION.HNEPEdition2019 },
  { label: '人教A版', value: EDITION.PEPEditionA },
  { label: '人教B版', value: EDITION.PEPEditionB },
  { label: '湘教版', value: EDITION.HNEPEdition },
  { label: '沪教版', value: EDITION.SEPEdition },
  { label: '人教版(2019)', value: EDITION.PEPEdition2019 },
  { label: '鲁教版(2019)', value: EDITION.LKJEdition2019 },
  { label: '初中总复习', value: EDITION.MSEEReview },
  { label: '高中总复习', value: EDITION.CollegeEntranceReview },
  { label: '浙教版', value: EDITION.ZHE_JIANG_EDITION },
  { label: '苏科版(2012)', value: EDITION.PSPRESS },
  { label: '苏科版(2024)', value: EDITION.PSPRESS_2024 },
  { label: '北师大版2024', value: EDITION.BJNU_EDITION_2024 },
  { label: '人教版2024', value: EDITION.PEP_EDITION_2024 },
  { label: '沪粤版(2012)', value: EDITION.HUYUE_EDITION_2012 },
  { label: '沪粤版(2024)', value: EDITION.HUYUE_EDITION_2024 },
  { label: '北京版(2012)', value: EDITION.BEIJING_2012 },
  { label: '北京版(2024)', value: EDITION.BEIJING_2024 },
  { label: '北师大版(北京版)(2012)', value: EDITION.BJNU_EDITION_BEIJING_2012 },
  { label: '北师大版(北京版)(2024)', value: EDITION.BJNU_EDITION_BEIJING_2024 }
]
/** ================================================ 版本--end ================================================ **/

/** ================================================ 年级/册别--start ================================================ **/
export enum GRADE {
  GRADEONE = 'GradeOne',
  GRADETWO = 'GradeTwo',
  GRADETHREE = 'GradeThree',
  GRADEFOUR = 'GradeFour',
  GRADEFIVE = 'GradeFive',
  GRADESIX = 'GradeSix',
  GRADESEVEN = 'GradeSeven',
  GRADEEIGHT = 'GradeEight',
  GRADENINE = 'GradeNine',
  GRADETEN = 'GradeTen',
  GRADEELEVEN = 'GradeEleven',
  GRADETWELVE = 'GradeTwelve'
}
export const primaryGrade: labelValueList<GRADE> = [
  { label: '一年级', value: GRADE.GRADEONE },
  { label: '二年级', value: GRADE.GRADETWO },
  { label: '三年级', value: GRADE.GRADETHREE },
  { label: '四年级', value: GRADE.GRADEFOUR },
  { label: '五年级', value: GRADE.GRADEFIVE },
  { label: '六年级', value: GRADE.GRADESIX }
]
export const secondaryGrade: labelValueList<GRADE> = [
  { label: '七年级', value: GRADE.GRADESEVEN },
  { label: '八年级', value: GRADE.GRADEEIGHT },
  { label: '九年级', value: GRADE.GRADENINE }
]
export const highGrade: labelValueList<GRADE> = [
  { label: '高一', value: GRADE.GRADETEN },
  { label: '高二', value: GRADE.GRADEELEVEN },
  { label: '高三', value: GRADE.GRADETWELVE }
]
export const period2Grade = {
  [PERIOD.PRIMARY_SCHOOL]: primaryGrade,
  [PERIOD.SECONDARY_SCHOOL]: secondaryGrade,
  [PERIOD.HIGH_SCHOOL]: highGrade
}
export const allGradeList: labelValueList<GRADE> = [...primaryGrade, ...secondaryGrade, ...highGrade]
export enum GRADE_CATEGORY {
  GRADE_1_ONE = 'Grade1One',
  GRADE_1_TWO = 'Grade1Two',
  GRADE_2_ONE = 'Grade2One',
  GRADE_2_TWO = 'Grade2Two',
  GRADE_3_ONE = 'Grade3One',
  GRADE_3_TWO = 'Grade3Two',
  GRADE_4_ONE = 'Grade4One',
  GRADE_4_TWO = 'Grade4Two',
  GRADE_5_ONE = 'Grade5One',
  GRADE_5_TWO = 'Grade5Two',
  GRADE_6_ONE = 'Grade6One',
  GRADE_6_TWO = 'Grade6Two',
  GRADE_7_ONE = 'Grade7One',
  GRADE_7_TWO = 'Grade7Two',
  GRADE_8_ONE = 'Grade8One',
  GRADE_8_TWO = 'Grade8Two',
  GRADE_9_ONE = 'Grade9One',
  GRADE_9_TWO = 'Grade9Two',
  GRADE_10_ONE = 'Grade10One',
  GRADE_10_TWO = 'Grade10Two',
  GRADE_11_ONE = 'Grade11One',
  GRADE_11_TWO = 'Grade11Two',
  GRADE_12_ONE = 'Grade12One',
  GRADE_12_TWO = 'Grade12Two',
}
export const primaryGradeList = [
  { label: '一年级（上）', value: GRADE_CATEGORY.GRADE_1_ONE },
  { label: '一年级（下）', value: GRADE_CATEGORY.GRADE_1_TWO },
  { label: '二年级（上）', value: GRADE_CATEGORY.GRADE_2_ONE },
  { label: '二年级（下）', value: GRADE_CATEGORY.GRADE_2_TWO },
  { label: '三年级（上）', value: GRADE_CATEGORY.GRADE_3_ONE },
  { label: '三年级（下）', value: GRADE_CATEGORY.GRADE_3_TWO },
  { label: '四年级（上）', value: GRADE_CATEGORY.GRADE_4_ONE },
  { label: '四年级（下）', value: GRADE_CATEGORY.GRADE_4_TWO },
  { label: '五年级（上）', value: GRADE_CATEGORY.GRADE_5_ONE },
  { label: '五年级（下）', value: GRADE_CATEGORY.GRADE_5_TWO },
  { label: '六年级（上）', value: GRADE_CATEGORY.GRADE_6_ONE },
  { label: '六年级（下）', value: GRADE_CATEGORY.GRADE_6_TWO }
]
export const primaryGradeObj = {
  title: '年级',
  value: 'grade',
  items: primaryGradeList
}
export const secondaryGradeList = [
  { label: '七年级（上）', value: GRADE_CATEGORY.GRADE_7_ONE },
  { label: '七年级（下）', value: GRADE_CATEGORY.GRADE_7_TWO },
  { label: '八年级（上）', value: GRADE_CATEGORY.GRADE_8_ONE },
  { label: '八年级（下）', value: GRADE_CATEGORY.GRADE_8_TWO },
  { label: '九年级（上）', value: GRADE_CATEGORY.GRADE_9_ONE },
  { label: '九年级（下）', value: GRADE_CATEGORY.GRADE_9_TWO }
]
export const secondaryGradeObj = {
  title: '年级',
  value: 'grade',
  items: secondaryGradeList
}
export const highGradeList = [
  { label: '高一（上）', value: GRADE_CATEGORY.GRADE_10_ONE },
  { label: '高一（下）', value: GRADE_CATEGORY.GRADE_10_TWO },
  { label: '高二（上）', value: GRADE_CATEGORY.GRADE_11_ONE },
  { label: '高二（下）', value: GRADE_CATEGORY.GRADE_11_TWO },
  { label: '高三（上）', value: GRADE_CATEGORY.GRADE_12_ONE },
  { label: '高三（下）', value: GRADE_CATEGORY.GRADE_12_TWO }
]
export const highGradeObj = {
  title: '年级',
  value: 'grade',
  items: highGradeList
}
export const period2GradeList = {
  [PERIOD.PRIMARY_SCHOOL]: primaryGradeList,
  [PERIOD.SECONDARY_SCHOOL]: secondaryGradeList,
  [PERIOD.HIGH_SCHOOL]: highGradeList
}

export enum Bookbooks { // 教材版本
  Grade1One = 'Grade1One', // 小学 一年级上
  Grade1Two = 'Grade1Two', // 一年级下
  Grade2One = 'Grade2One', // 二年级上
  Grade2Two = 'Grade2Two', // 二年级下
  Grade3One = 'Grade3One', // 三年级上
  Grade3Two = 'Grade3Two', // 三年级下
  Grade4One = 'Grade4One', // 四年级上
  Grade4Two = 'Grade4Two', // 四年级下
  Grade5One = 'Grade5One', // 五年级上
  Grade5Two = 'Grade5Two', // 五年级下
  Grade6One = 'Grade6One', // 六年级上
  Grade6Two = 'Grade6Two', // 六年级下
  Grade7One = 'Grade7One', // 初中 七年级上
  Grade7Two = 'Grade7Two', // 七年级下
  Grade8One = 'Grade8One', // 八年级上
  Grade8Two = 'Grade8Two', // 八年级下
  Grade9One = 'Grade9One', // 九年级上
  Grade9Two = 'Grade9Two', // 九年级下
  Required1 = 'Required1', // 高中 必修一
  Required2 = 'Required2', // 必修二
  Required3 = 'Required3', // 必修三
  Required4 = 'Required4', // 必修四
  Required5 = 'Required5', // 必修五
  Elective1 = 'Elective1', // 选修一
  Elective2 = 'Elective2', // 选修二
  Elective3 = 'Elective3', // 选修三
  Elective4 = 'Elective4', // 选修四
  Elective5 = 'Elective5', // 选修五
  Elective6 = 'Elective6', // 选修六
  Elective1_1 = 'Elective1_1', // 选修1-1
  Elective1_2 = 'Elective1_2', // 选修1-2
  Elective2_1 = 'Elective2_1', // 选修2-1
  Elective2_2 = 'Elective2_2', // 选修2-2
  Elective2_3 = 'Elective2_3', // 选修2-3
  Elective4_1 = 'Elective4_1', // 选修4-1
  Elective4_2 = 'Elective4_2', // 选修4-2
  Elective4_3 = 'Elective4_3', // 选修4-3
  Elective4_4 = 'Elective4_4', // 选修4-4
  Elective4_5 = 'Elective4_5', // 选修4-5
  ReviewRound1 = 'ReviewRound1', // 一轮复习
  ReviewRound2 = 'ReviewRound2', // 二轮复习
  ReviewRound3 = 'ReviewRound3', // 三轮复习
  None = 'None' // 备用
}
export const primaryBookList = [
  { label: '一年级（上）', value: Bookbooks.Grade1One },
  { label: '一年级（下）', value: Bookbooks.Grade1Two },
  { label: '二年级（上）', value: Bookbooks.Grade2One },
  { label: '二年级（下）', value: Bookbooks.Grade2Two },
  { label: '三年级（上）', value: Bookbooks.Grade3One },
  { label: '三年级（下）', value: Bookbooks.Grade3Two },
  { label: '四年级（上）', value: Bookbooks.Grade4One },
  { label: '四年级（下）', value: Bookbooks.Grade4Two },
  { label: '五年级（上）', value: Bookbooks.Grade5One },
  { label: '五年级（下）', value: Bookbooks.Grade5Two },
  { label: '六年级（上）', value: Bookbooks.Grade6One },
  { label: '六年级（下）', value: Bookbooks.Grade6Two }
]
export const secondaryBookList = [
  { label: '七年级（上）', value: Bookbooks.Grade7One },
  { label: '七年级（下）', value: Bookbooks.Grade7Two },
  { label: '八年级（上）', value: Bookbooks.Grade8One },
  { label: '八年级（下）', value: Bookbooks.Grade8Two },
  { label: '九年级（上）', value: Bookbooks.Grade9One },
  { label: '九年级（下）', value: Bookbooks.Grade9Two }
]
export const highBookList = [
  { label: '必修一', value: Bookbooks.Required1 },
  { label: '必修二', value: Bookbooks.Required2 },
  { label: '必修三', value: Bookbooks.Required3 },
  { label: '必修四', value: Bookbooks.Required4 },
  { label: '必修五', value: Bookbooks.Required5 },

  { label: '选修一', value: Bookbooks.Elective1 },
  { label: '选修二', value: Bookbooks.Elective2 },
  { label: '选修三', value: Bookbooks.Elective3 },
  { label: '选修四', value: Bookbooks.Elective4 },
  { label: '选修五', value: Bookbooks.Elective5 },
  { label: '选修六', value: Bookbooks.Elective6 },

  { label: '选修1-1', value: Bookbooks.Elective1_1 },
  { label: '选修1-2', value: Bookbooks.Elective1_2 },
  { label: '选修2-1', value: Bookbooks.Elective2_1 },
  { label: '选修2-2', value: Bookbooks.Elective2_2 },
  { label: '选修2-3', value: Bookbooks.Elective2_3 },
  { label: '选修4-1', value: Bookbooks.Elective4_1 },
  { label: '选修4-2', value: Bookbooks.Elective4_2 },
  { label: '选修4-3', value: Bookbooks.Elective4_3 },
  { label: '选修4-4', value: Bookbooks.Elective4_4 },
  { label: '选修4-5', value: Bookbooks.Elective4_5 }
]
export const reviewRoundsList = [
  { label: '一轮复习', value: Bookbooks.ReviewRound1 },
  { label: '二轮复习', value: Bookbooks.ReviewRound2 },
  { label: '三轮复习', value: Bookbooks.ReviewRound3 }
]
/** ================================================ 年级/册别--end ================================================ **/

