// 获取样例文件列表
export function getSampleFiles(requestData: chapterTypes.ChapterFiles, key = 'getSampleFiles') {
  const { chapterId, ...query } = requestData
  const url = `chapters/${chapterId}/sampleFiles`
  return $request<chapterTypes.GetSampleFile | { code: number }>(key, { url }, { method: 'get', query })
}

// 获取课时的样例文件列表
export function getLessonSampleFiles(requestData: chapterTypes.LessonFiles, key = 'getLessonSampleFiles') {
  const { lessonId, ...query } = requestData
  const url = `lessons/${lessonId}/sampleFiles`
  return $request<chapterTypes.SampleFileModel[] | { code: number }>(key, { url }, { method: 'get', query })
}

// 根据章节获取样例列表（新分页接口）
export function getChapterSampleFiles(requestData: chapterTypes.GetChapterSampleFiles, key = 'getChapterSampleFiles') {
  const { chapterId, ...body } = requestData
  const url = `/chapters/${chapterId}/samplefiles/list`
  return $request(key, { url }, { method: 'post', body })
}

// 获取类别列表
export function getSampleFileTypes(requestData?: chapterTypes.GetSampleFileTypes, key = 'getSampleFileTypes') {
  const url = '/samplefiles/types'
  return $request<chapterTypes.SampleFileTypesModel[]>(key, { url }, { query: requestData })
}

// 根据章节、类别获取文件
