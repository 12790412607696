import * as common from './utils/common'
import mitt from './utils/mitt'
import * as rules from './utils/rules'
import * as cypress from './utils/cypress'
import * as keycloak from './utils/keycloak'
import * as oauthLogin from './utils/oauthLogin'

export const $utils = {
  ...common,
  mitt,
  ...rules,
  ...cypress,
  ...keycloak,
  ...oauthLogin
}
