import plugin_vue3_A0OWXRrUgq from "/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import unhead_KgADcZ0jPj from "/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import vueuse_head_polyfill_M7DKUOwKp5 from "/app/node_modules/nuxt/dist/head/runtime/plugins/vueuse-head-polyfill.js";
import router_jmwsqit4Rs from "/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import prefetch_client_5tzzN0oIVL from "/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import pwa_client_Eorgdr12jA from "/app/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.mjs";
import element_plus_teleports_plugin_h4Dmekbj62 from "/app/.nuxt/element-plus-teleports.plugin.mjs";
import element_plus_injection_plugin_1RNPi6ogby from "/app/.nuxt/element-plus-injection.plugin.mjs";
import chunk_reload_client_UciE0i6zes from "/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_1UohGbtF8v from "/app/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.mjs";
import pinia_client_l2mvTNw9P0 from "/app/plugins/pinia.client.ts";
import viewer_ARKVwp6nTF from "/app/plugins/viewer.ts";
import vue_config_qvnc1Nkw7F from "/app/plugins/vue-config.ts";
import vue_1smQoUXlyb from "/app/plugins/vue.ts";
import vueKonva_client_DN0fdF4h9j from "/app/plugins/vueKonva.client.ts";
export default [
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  unhead_KgADcZ0jPj,
  vueuse_head_polyfill_M7DKUOwKp5,
  router_jmwsqit4Rs,
  prefetch_client_5tzzN0oIVL,
  pwa_client_Eorgdr12jA,
  element_plus_teleports_plugin_h4Dmekbj62,
  element_plus_injection_plugin_1RNPi6ogby,
  chunk_reload_client_UciE0i6zes,
  plugin_1UohGbtF8v,
  pinia_client_l2mvTNw9P0,
  viewer_ARKVwp6nTF,
  vue_config_qvnc1Nkw7F,
  vue_1smQoUXlyb,
  vueKonva_client_DN0fdF4h9j
]