export function getUserInfo(key = 'getUserInfo') {
  const url = '/user/teacher'
  return $request<userTypes.TeacherInfo>(key, { url })
}

// 查看 AI组卷、文件生成、上传学生答题卡 进度
export function getTaskStatus(key = 'getTaskStatus') {
  const url = '/jobs'
  return $request<userTypes.TaskStatus[]>(key, { url })
}

// qxLogin根据code获取cookie
export function getUserCookie(code: string, key = 'getUserCookie') {
  const url = `/user/cookie/tenant/gaoshou/code/${code}`
  return $request(key, { url })
}
