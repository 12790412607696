// 新增考试
export function addNewExam(requestData: examManageTypes.AddNewExamReq, key = 'addNewExam') {
  const url = '/exams'
  return $request(key, { url }, { method: 'post', body: requestData })
}

// 获取考试列表
export function getExamsList(requestData: examManageTypes.GetExamsReq, key = 'getExamsList') {
  const url = '/exams/list'
  return $request<examManageTypes.GetExamsRes>(key, { url }, { method: 'post', body: requestData })
}

// 删除考试
export function removeExam(requestData: { examId: number }, key = 'removeExam') {
  const { examId } = requestData
  const url = `/exams/${examId}`
  return $request<null>(key, { url }, { method: 'delete' })
}

// 获取考试详情
export function getExamInfo(requestData: examManageTypes.GetExamInfoReq, key = 'getExamInfo') {
  const { id, q, sort } = requestData
  const url = `/exams/${id}/studentScores`
  return $request<examManageTypes.GetExamInfoRes>(key, { url }, { method: 'post', query: { q, sort }, body: requestData })
}

// 检查文件是否上传过
export function viewFileIsUpload(requestData: { id: number }, key = 'viewFileIsUpload') {
  const { id } = requestData
  const url = `/exams/${id}/info`
  return $request<examManageTypes.ViewFileIsUploadRes>(key, { url })
}

// 下载考试信息文件
export function downloadExamDetailFile(requestData: examManageTypes.ExamDetailFile, key = 'downloadExamDetailFile') {
  const url = '/exams/file'
  return $request<{recordId: number}>(key, { url }, { method: 'post', body: requestData })
}

// 保存答题卡题目切片
export function saveAnswerSheetSlices(requestData: examManageTypes.saveAnswerSheetReq, key = 'saveAnswerSheetSlices') {
  const { examId, answerSheetSlices } = requestData
  const url = `/exams/${examId}/questions`
  return $request(key, { url }, { method: 'post', body: answerSheetSlices })
}

// 获取考试题目列表
export function getExamQuestions(requestData: { id: number }, key = 'getExamQuestions') {
  const { id } = requestData
  const url = `/exams/${id}/questions`
  return $request<examManageTypes.CorrectExamQuestionsRes>(key, { url })
}

// 获取考试题目列表
export function getExamQuestionByEqid(requestData: examManageTypes.ExamQuestionByEqidReq, key = 'getExamQuestionByEqid') {
  const { examId, examQuestionId } = requestData
  const url = `/exams/${examId}/questions/${examQuestionId}`
  return $request<examManageTypes.ExamQuestionByEqidRes>(key, { url })
}

// 获取上传文件处理状态（题目对应知识点，学生每道题得分、批量上传学生信息）
export function getFileProcessStatus(requestData: examManageTypes.FileProcessStatusReq, key = 'getFileProcessState') {
  const url = '/exams/upload/status'
  return $request<examManageTypes.FileProcessStatusRes>(key, { url }, { method: 'post', body: requestData })
}

// 获取答题卡上传处理状态
export function getAnswerSheetStatus(requestData: { examId: number }, key = 'getAnswerSheetStatus') {
  const { examId } = requestData
  const url = `/exams/${examId}/answerCards/status`
  return $request<examManageTypes.AnswerSheetStatus>(key, { url })
}

// 切换下一份试卷
export function switchExamPaper(requestData: examManageTypes.SwitchExamPaperReq, key = 'switchExamPaper') {
  const { examId, eqId, scanId, backward, size } = requestData
  const url = `/exams/${examId}/questions/${eqId}/scans/${scanId}`
  return $request<examManageTypes.SwitchExamPaperRes>(key, { url }, { query: { backward, size }})
}

// 试卷题目打分
export function markExamPaper(requestData: examManageTypes.MarkExamPaperReq, key = 'markExamPaper') {
  const { id, qid, studentId, score } = requestData
  const url = `/exams/${id}/questions/${qid}/students/${studentId}/score/${score}`
  return $request(key, { url }, { method: 'post' })
}

// 上传空白答题卡
export function uploadAnwserCards(requestData, key = 'uploadAnwserCards') {
  const { examId, formData } = requestData
  const url = `/exams/${examId}/anwserCards`
  return $request<examManageTypes.UploadAnwserCardsRes>(key, { url }, { method: 'post', body: formData })
}

// 分片上传答题卡压缩包
export function uploadAnwserCardPack(requestData: { examId: number, formData: FormData}, key = 'uploadAnwserCardPack') {
  const { examId, formData } = requestData
  console.log('uploadAnwserCardPackuploadAnwserCardPack')
  const url = `exams/${examId}/answerCards/chunks/${formData.get('chunk')}`
  return $request<string>(key, { url }, { method: 'post', body: formData })
}

// 合并分片
export function mergeAnwserCardPack(requestData, key = 'mergeAnwserCardPack') {
  const { examId, formData } = requestData
  const url = `exams/${examId}/mergefile`
  return $request(key, { url }, { method: 'post', body: formData })
}

// 获取未识别成功答题卡
export function getUnrecognizedScans(requestData: { examId: number }, key = 'getUnrecognizedScans') {
  const { examId } = requestData
  const url = `/exams/${examId}/unrecognizedScans`
  return $request<examManageTypes.UnrecognizedScans[]>(key, { url })
}

// 获取某个未识别成功答题卡
export function getAnswerSheetUrl(requestData: examManageTypes.ExamAnswerSheet, key = 'getAnswerSheetUrl') {
  const { examId, scanId } = requestData
  const url = `/exams/${examId}/scans/${scanId}`
  return $request<examManageTypes.AnswerSheetUrlRes>(key, { url })
}

// 删除某个未识别成功答题卡
export function removeUnrecognizedScans(requestData: examManageTypes.ExamAnswerSheet, key = 'removeExam') {
  const { examId, scanId } = requestData
  const url = `/exams/${examId}/scans/${scanId}`
  return $request<null>(key, { url }, { method: 'delete' })
}

// 手动输入考生号
export function inputStudentNumber(requestData: examManageTypes.inputStudentNumberReq, key = 'inputStudentNumber') {
  const { examId, scanId, ...studentNumber } = requestData
  const url = `/exams/${examId}/scans/${scanId}`
  return $request(key, { url }, { method: 'put', body: studentNumber })
}

// 获取某个学生答题卡
export function getStudentTestPaper(requestData: examManageTypes.StudentTestPaperReq, key = 'getStudentTestPaper') {
  const { examId, studentId, page } = requestData
  const url = `/exams/${examId}/students/${studentId}/scan`
  return $request<examManageTypes.StudentTestPaperRes>(key, { url }, { query: { page }})
}

// 上传题目对应知识点
export function uploadKnowledgeWithQuestion(requestData, key = 'uploadKnowledgeWithQuestion') {
  const { examId, formData } = requestData
  const url = `/exams/${examId}/type/knowledgePoint/upload`
  return $request(key, { url }, { method: 'post', body: formData })
}

// 上传文件
export function uploadStandardAnswer(requestData, key = 'uploadStandardAnswer') {
  const { id, formData } = requestData
  const url = `/exams/${id}/answer`
  return $request(key, { url }, { method: 'post', body: formData })
}

// 获取学生考试各题得分
export function getStudentEachQuestionScore(requestData: examManageTypes.GetStudentEachQuestionScoreReq, key = 'getStudentEachQuestionScore') {
  const { examId, studentId } = requestData
  const url = `/exams/${examId}/students/${studentId}/questionscore`
  return $request<examManageTypes.GetEachQuestionInfo>(key, { url })
}

// 修改学生考试各题扽分
export function modifyStudentExahQuestionScore(requestData: examManageTypes.ModifyStudentEachQuestionScore, key = 'modifyStudentExahQuestionScore') {
  const { examId, studentId, ...body } = requestData
  const url = `/exams/${examId}/students/${studentId}/questionscore`
  return $request(key, { url }, { method: 'PATCH', body })
}

// 获取已选择题答案
export function getChoiceAnswer(requestData: examManageTypes.GetChoiceAnswerReq, key = 'getChoiceAnswer') {
  const { examId } = requestData
  const url = `/exams/${examId}/choice`
  return $request<examManageTypes.GetChoiceAnswerRes>(key, { url })
}

// 批量操作选择题答案
export function batchOperateChoiceAnswer(requestData: examManageTypes.BatchChoiceAnswerReq, key = 'batchOperateChoiceAnswer') {
  const { examId, batchList } = requestData
  const url = `/exams/${examId}/choice`
  return $request(key, { url }, { method: 'PATCH', body: batchList })
}

// 获取答题卡切片列表
export function getAnswerSheetSlices(requestData: examManageTypes.GetAnswerSheetSlicesReq, key = 'getAnswerSheetSlices') {
  const { examId, page } = requestData
  const url = `/exams/${examId}/questions/pages/${page}`
  return $request<examManageTypes.GetAnswerSheetSlicesRes>(key, { url })
}

// 批量操作答题卡切片
export function batchProcessAnswerSlice(requestData: examManageTypes.BatchProcessAnswerSlice, key = 'batchProcessAnswerSlice') {
  const { examId, batchList } = requestData
  const url = `/exams/${examId}/answer-sheet/slices`
  return $request(key, { url }, { method: 'PATCH', body: batchList })
}

// 删除空白答题卡
export function removeAnwserCard(requestData: { examId: number, answerCardId: number }, key = 'removeAnwserCard') {
  const { examId, answerCardId } = requestData
  const url = `/exams/${examId}/answerCards/${answerCardId}`
  return $request(key, { url }, { method: 'delete' })
}

// 上传选择题切片信息
export function uploadChoiceQuestionSlice(requestData: examManageTypes.UploadChoiceQuestionSliceReq, key = 'uploadChoiceQuestionSlice') {
  const { examId, ...body } = requestData
  const url = `/exams/${examId}/choice-questions`
  return $request<examManageTypes.UploadChoiceQuestionSliceRes>(key, { url }, { method: 'post', body })
}

// 获取年级内班级的学科目标分
export function getGradeTargetScore(requestData: { groupId: number | string, subject: string }, key = 'getGradeTargetScore') {
  const { groupId, subject } = requestData
  const url = `/group/${groupId}/subjects/${subject}/targetscore`
  return $request<examManageTypes.GradeTargetScore>(key, { url }, { method: 'get' })
}

// 修改班级的目标分
export function changeClassTargetScore(requestData: { groupId: number, subject: string, body: {
  groupId: number, targetScore: number
} }, key = 'changeClassTargetScore') {
  const { groupId, subject, body } = requestData
  const url = `/group/${groupId}/subjects/${subject}/targetscore`
  return $request<examManageTypes.ClassTargetScoreRes>(key, { url }, { method: 'put', body })
}

export function examDownloadUrl(id: number, key = 'examDownloadUrl') {
  const url = `/exams/${id}/file/download`
  return $request<examManageTypes.examDownloadUrlRes>(key, { url }, { method: 'get' })
}

// 获取考试答案
export function getExamAnswer(id: number, key = 'getExamAnswer') {
  const url = `/exams/${id}/answer`
  return $request<examManageTypes.examAnswer>(key, { url }, { method: 'get' })
}

// 题卡合一获取学生试卷
export function getOneMergeExam(requestData: examManageTypes.oneMergeExamReq, key = 'getOneMergeExam') {
  const { examId, groupId, page } = requestData
  const url = `/exams/${examId}/groups/${groupId}/pages/${page}`
  return $request<examManageTypes.oneMergeExamRes>(key, { url })
}

// 获取题卡合一班级列表信息
export function getExamsGroups(requestData: { examId: number }, key = 'getExamsGroups') {
  const { examId } = requestData
  const url = `/exams/${examId}/groups`
  return $request<examManageTypes.ExamMergeGroupModel>(key, { url })
}

// 获取考试选择题答案
export function getExamsSelectAnswer(id: number, key = 'getExamsSelectAnswer') {
  const url = `/exams/${id}/choice/answer`
  return $request<homeworkTypes.SelectQuestionAnswerRes>(key, { url }, { method: 'get' })
}

// 修改考试选择题答案
export function changeExamsSelectAnswer(requestData: homeworkTypes.selectQuestionChangeReq, key = 'changeExamsSelectAnswer') {
  const url = `/exams/${requestData.id}/choice/answer`
  return $request(key, { url }, { method: 'PATCH', body: requestData.questions })
}

// 修改校本记录展示设置
export function setExamsIsShare(requestData: project.IsShareModel, key = 'setExamsIsShare') {
  const { id, isShare } = requestData
  const url = `/exams/${id}/pub?pub=${isShare}`
  return $request(key, { url }, { method: 'put' })
}
