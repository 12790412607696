import * as textbook from './apis/textbook'
import * as chapter from './apis/chapter'
import * as database from './apis/database'
import * as computedDownload from './apis/computedDownload'
import * as testPaper from './apis/testPaper'
import * as teacherManage from './apis/teacherManage'
import * as studentManage from './apis/studentManage'
import * as home from './apis/home'
import * as user from './apis/user'
import * as examManage from './apis/examManage'
import * as portrait from './apis/portrait'
import * as group from './apis/group'
import * as homework from './apis/homework'
import * as printer from './apis/printer'
import * as thirdLogin from './apis/thirdLogin'
import * as individualWorks from './apis/individualWorks'

export const $apis = {
  textbook,
  chapter,
  database,
  computedDownload,
  testPaper,
  teacherManage,
  studentManage,
  home,
  user,
  examManage,
  portrait,
  group,
  homework,
  printer,
  thirdLogin,
  individualWorks
}
