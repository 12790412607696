import { defineStore } from 'pinia'

export const useTextbookStore = defineStore('textbook', () => {
  const chaptersInfo = ref([])

  function setChaptersInfo(state) {
    chaptersInfo.value = state
  }

  async function requestTextbook() {
    const { teachingMaterialInfo } = $stores.user.useUserStore()
    const reqData: textbookTypes.SwitchTextbookReq = {
      period: teachingMaterialInfo.valueList[0],
      subject: teachingMaterialInfo.valueList[1],
      edition: teachingMaterialInfo.valueList[2],
      grade: teachingMaterialInfo.valueList[3]
    }
    const { data, error } = await $apis.textbook.switchTextbook(reqData)
    if (error.value) return
    const dataValue = data.value as textbookTypes.SwitchTextbookRes[]
    dataValue.forEach(chapter => {
      chapter.name = chapter.chapterName
      chapter.isChapter = true
      chapter.treeId = `chapter${chapter.chapterId}`
      if (chapter.lessons) {
        chapter.lessons.forEach(lesson => {
          lesson.treeId = `lesson${lesson.id}`
        })
      }
    })
    setChaptersInfo(dataValue)
    return dataValue
  }

  return {
    chaptersInfo,
    setChaptersInfo,
    requestTextbook
  }
})

