/**
 * 说明：资料库页枚举
*/

import * as teachingMaterialEnums from './teachingMaterialEnums'

const {
  PERIOD,
  primaryGradeObj,
  secondaryGradeObj,
  highGradeObj
} = teachingMaterialEnums

/** ================================================ 题型难易度--start ================================================ **/
export enum DIFFICULTY_DEGREE {
  SIMPLE = 'Simple',
  MODERATELY = 'Moderately',
  HARD = 'Hard',
  Basic = 'Basic'
}
/** 难易度-初中 **/
export const secondDifficultyDegreeList: labelValueList<DIFFICULTY_DEGREE> = [
  { label: '容易', value: DIFFICULTY_DEGREE.SIMPLE },
  { label: '一般', value: DIFFICULTY_DEGREE.MODERATELY },
  { label: '困难', value: DIFFICULTY_DEGREE.HARD }
]
export const secondDifficultyDegreeObj = {
  title: '难易度',
  value: 'difficultyDegree',
  items: secondDifficultyDegreeList
}
/** 难易度-高中 **/
export const highDifficultyDegreeList: labelValueList<DIFFICULTY_DEGREE> = [
  { label: '基础', value: DIFFICULTY_DEGREE.Basic },
  { label: '容易', value: DIFFICULTY_DEGREE.SIMPLE },
  { label: '中等', value: DIFFICULTY_DEGREE.MODERATELY },
  { label: '难题', value: DIFFICULTY_DEGREE.HARD }
]
export const highDifficultyDegreeObj = {
  title: '难易度',
  value: 'difficultyDegree',
  items: highDifficultyDegreeList
}
/** ================================================ 题型难易度--end ================================================ **/

/** ================================================ 题型分类--start ================================================ **/
export enum QUESTION_CLASSIFICATION {
  ERROR_PRONE = 'ErrorProne', // 0 易错题   小学初中
  SIMULATION = 'Simulation', // 1 模拟题   小学初中高中
  PAST_YEARS_PAPER = 'PastYearsPaper', // 2 真题    小学初中高中
  INNOVATION = 'Innovation', // 3 创新提  小学初中
  OTHER = 'Other', // 4 其他
  StageTesting = 'StageTesting', // 5 阶段测试  初中高中
  ModuleFoundation = 'ModuleFoundation', // 6 模拟基础  高中
  COMPETITION = 'Competition', // 7 竞赛题
  PHASE_EXERCISE = 'PhaseExercise', // 8 阶段练习
  HIGH_SCHOOL_ENTRANCE_SIMULATION = 'HighSchoolEntranceSimulation', // 9 中考模拟
  HIGH_SCHOOL_ENTRANCE_REAL = 'HighSchoolEntranceReal', // 10 中考真题
}
/** 题型分类-初中 **/
export const secondQuestionClassificationList: labelValueList<QUESTION_CLASSIFICATION> = [
  { label: '阶段练习', value: QUESTION_CLASSIFICATION.PHASE_EXERCISE },
  { label: '阶段测试', value: QUESTION_CLASSIFICATION.StageTesting },
  { label: '中考模拟', value: QUESTION_CLASSIFICATION.SIMULATION },
  { label: '中考真题', value: QUESTION_CLASSIFICATION.PAST_YEARS_PAPER },
  { label: '竞赛题', value: QUESTION_CLASSIFICATION.COMPETITION }
]
export const secondQuestionClassificationObj = {
  title: '来源',
  value: 'questionClassification',
  items: secondQuestionClassificationList
}
/** 题型分类-高中 **/
export const highQuestionClassificationList = [
  { label: '真题', value: QUESTION_CLASSIFICATION.PAST_YEARS_PAPER },
  { label: '模拟题', value: QUESTION_CLASSIFICATION.SIMULATION },
  { label: '阶段测试', value: QUESTION_CLASSIFICATION.StageTesting },
  { label: '模块基础', value: QUESTION_CLASSIFICATION.ModuleFoundation },
  { label: '竞赛题', value: QUESTION_CLASSIFICATION.COMPETITION }
]
export const highQuestionClassificationObj = {
  title: '类型',
  value: 'questionClassification',
  items: highQuestionClassificationList
}
/** ================================================ 题型分类--end ================================================ **/

/** ================================================ 题型--start ================================================ **/
/** 题型--start **/
/**
 * @property MULTIPLE_CHOICE - 选择题
 * @property FILL_SINGLE_BLANK - 单项填空题
 * @property FILL_MULTIPLE_BLANK - 多项填空题
 * @property CALCULATION - 计算题
 * @property APPLICATION - 应用题
 * @property TRUE_OR_FALSE - 判断题
 * @property ARITHMETIC - 简答题
 * @property ADVENTITIOUS_OPTIONS - 多项选择题
 * @property MULTIPLE_PROBLEMS - 一题多问
 * @property EXPERIMENT - 试验探究题
 */
export enum EXERCISE_TYPE {
  MULTIPLE_CHOICE = 'Choice', // 选择题
  FILL_BLANK = 'FillInTheBlank', // 填空题
  FILL_SINGLE_BLANK = 'SingleFillInTheBlank', // 单项填空题
  FILL_MULTIPLE_BLANK = 'MultipleFillInTheBlank', // 多项填空题
  CALCULATION = 'Calculation', // 计算题
  APPLICATION = 'Application', // 应用题
  TRUE_OR_FALSE = 'TorF', // 判断题
  MULTIPLE_PROBLEMS = 'MultipleProblems', // 一题多问
  ARITHMETIC = 'Arithmetic', // 简答题
  ADVENTITIOUS_OPTIONS = 'MultipleChoice', // 多项选择题
  EXPERIMENT = 'Experiment', // 实验题
  DRAWING = 'Drawing', // 作图题
  COMPREHENSIVE = 'Comprehensive', // 综合题
  POPULARIZATION = 'Popularization' // 科普阅读题
}
export const exerciseTypeList: labelValueList<EXERCISE_TYPE> = [
  { label: '选择题', value: EXERCISE_TYPE.MULTIPLE_CHOICE },
  { label: '多项选择题', value: EXERCISE_TYPE.ADVENTITIOUS_OPTIONS },
  { label: '填空题', value: EXERCISE_TYPE.FILL_BLANK },
  { label: '计算题', value: EXERCISE_TYPE.CALCULATION },
  { label: '解答题', value: EXERCISE_TYPE.APPLICATION },
  { label: '判断题', value: EXERCISE_TYPE.TRUE_OR_FALSE },
  { label: '解答题', value: EXERCISE_TYPE.MULTIPLE_PROBLEMS },
  { label: '实验题', value: EXERCISE_TYPE.EXPERIMENT },
  { label: '作图题', value: EXERCISE_TYPE.DRAWING },
  { label: '综合题', value: EXERCISE_TYPE.COMPREHENSIVE },
  { label: '科普阅读题', value: EXERCISE_TYPE.POPULARIZATION }
]
export const exerciseTypeObj = {
  title: '题型',
  value: 'questionType',
  items: [
    { label: '选择题', value: EXERCISE_TYPE.MULTIPLE_CHOICE },
    { label: '多项选择题', value: EXERCISE_TYPE.ADVENTITIOUS_OPTIONS },
    { label: '填空题', value: EXERCISE_TYPE.FILL_BLANK },
    { label: '计算题', value: EXERCISE_TYPE.CALCULATION },
    { label: '解答题', value: EXERCISE_TYPE.APPLICATION },
    { label: '判断题', value: EXERCISE_TYPE.TRUE_OR_FALSE }
  ]
}
// 需求，在高手网里 应用题和一题多问合并为解答题。在试卷部分，这两者会在后端自动合并为 Application；在题目显示部分，后端的返回值还会存在一题多问的值。
/** ================================================ 题型--end ================================================ **/

/** ================================================ 日期--start ================================================ **/
/** 日期-初中 **/
export const getSecondTimeList = function() {
  const date = new Date()
  const year = date.getFullYear()
  // const month = date.getMonth() + 1
  // const day = date.getDate()
  const startYear = 2016
  const endYear = year
  const res: { label: string, value: string }[] = [{ label: '2015及以前', value: '2015及以前' }]
  for (let year = startYear; year <= endYear; year++) {
    res.push({
      label: year.toString(),
      value: year.toString()
    })
  }
  return res
}
export const secondTimeObj = {
  title: '时间',
  value: 'time',
  items: getSecondTimeList()
}
/** 日期-高中 **/
export const getHighTimeList = function() {
  const date = new Date()
  const year = date.getFullYear()
  // const month = date.getMonth() + 1
  // const day = date.getDate()
  const startYear = 2013
  const endYear = year
  const res: { label: string, value: string }[] = []
  for (let year = startYear; year <= endYear; year++) {
    res.push({
      label: year.toString(),
      value: year.toString()
    })
  }
  return res
}
export const highTimeObj = {
  title: '时间',
  value: 'time',
  items: getHighTimeList()
}
/** ================================================ 日期--end ================================================ **/

/** ================================================ 考法--start ================================================ **/
/** 考法-高中 **/
export enum HIGH_METHODS {
  None = 'None',
  Simplification = 'Simplification',
  Proof = 'Proof',
  Drawing = 'Drawing',
  ShortAnswer = 'ShortAnswer',
  PracticalApplication = 'PracticalApplication',
  SolutionSelection = 'SolutionSelection',
  TrueOrFalse = 'TrueOrFalse'
}
export const highMethodsList: labelValueList<HIGH_METHODS> = [
  { label: '计算化简', value: HIGH_METHODS.Simplification },
  { label: '证明', value: HIGH_METHODS.Proof },
  { label: '作图', value: HIGH_METHODS.Drawing },
  { label: '普通简答', value: HIGH_METHODS.ShortAnswer },
  { label: '实际应用', value: HIGH_METHODS.PracticalApplication },
  { label: '方案选择', value: HIGH_METHODS.SolutionSelection },
  { label: '判断正误', value: HIGH_METHODS.TrueOrFalse }
]
export const highMethodsObj = {
  title: '考法',
  value: 'method',
  items: highMethodsList
}
/** ================================================ 考法--end ================================================ **/

/** ================================================ 地区--start ================================================ **/
export enum Province {
  Other = 'Other', // 其他
  All = 'All', // 全国
  Jing = 'Jing', // 北京
  Jin = 'Jin', // 天津
  Wan = 'Wan', // 安徽
  Shen = 'Shen', // 上海
  Min = 'Min', // 福建
  Ba = 'Ba', // 重庆
  Gan = 'Gan', // 江西
  Lu = 'Lu', // 山东
  Yu = 'Yu', // 河南
  Meng = 'Meng', // 内蒙古
  E = 'E', // 湖北
  Jiang = 'Jiang', // 新疆
  Xiang = 'Xiang', // 湖南
  Ning = 'Ning', // 宁夏
  Yue = 'Yue', // 广东
  Zang = 'Zang', // 西藏
  Qiong = 'Qiong', // 海南
  Gui = 'Gui', // 广西
  Shu = 'Shu', // 四川
  Li = 'Li', // 河北
  Qian = 'Qian', // 贵州
  Jin2 = 'Jin2', // 山西
  Dian = 'Dian', // 云南
  Liao = 'Liao', // 辽宁
  Qin = 'Qin', // 陕西
  Ji = 'Ji', // 吉林
  Long = 'Long', // 甘肃
  Hei = 'Hei', // 黑龙江
  Qing = 'Qing', // 青海
  Su = 'Su', // 江苏
  Zhe = 'Zhe' // 浙江
}
export const provinceList = [
  { label: '全国', value: Province.All, initial: '' },
  { label: '北京', value: Province.Jing, initial: 'B' },
  { label: '天津', value: Province.Jin, initial: 'T' },
  { label: '安徽', value: Province.Wan, initial: 'A' },
  { label: '上海', value: Province.Shen, initial: 'S' },
  { label: '福建', value: Province.Min, initial: 'F' },
  { label: '重庆', value: Province.Ba, initial: 'C' },
  { label: '江西', value: Province.Gan, initial: 'J' },
  { label: '山东', value: Province.Lu, initial: 'S' },
  { label: '河南', value: Province.Yu, initial: 'H' },
  { label: '内蒙古', value: Province.Meng, initial: 'N' },
  { label: '湖北', value: Province.E, initial: 'H' },
  { label: '新疆', value: Province.Jiang, initial: 'X' },
  { label: '湖南', value: Province.Xiang, initial: 'H' },
  { label: '宁夏', value: Province.Ning, initial: 'N' },
  { label: '广东', value: Province.Yue, initial: 'G' },
  { label: '西藏', value: Province.Zang, initial: 'X' },
  { label: '海南', value: Province.Qiong, initial: 'H' },
  { label: '广西', value: Province.Gui, initial: 'G' },
  { label: '四川', value: Province.Shu, initial: 'S' },
  { label: '河北', value: Province.Li, initial: 'H' },
  { label: '贵州', value: Province.Qian, initial: 'G' },
  { label: '山西', value: Province.Jin2, initial: 'S' },
  { label: '云南', value: Province.Dian, initial: 'Y' },
  { label: '辽宁', value: Province.Liao, initial: 'L' },
  { label: '陕西', value: Province.Qin, initial: 'S' },
  { label: '吉林', value: Province.Ji, initial: 'J' },
  { label: '甘肃', value: Province.Long, initial: 'G' },
  { label: '黑龙江', value: Province.Hei, initial: 'H' },
  { label: '青海', value: Province.Qing, initial: 'Q' },
  { label: '江苏', value: Province.Su, initial: 'J' },
  { label: '浙江', value: Province.Zhe, initial: 'Z' }
]
export const provinceObj = {
  title: '地区',
  value: 'area',
  hidAll: true,
  defaultAll: Province.All,
  items: provinceList
}
/** ================================================ 地区--end ================================================ **/

/** ================================================ 根据学段 获取筛选题目条件（单选/多选）--start ================================================ **/
export const period2questionObj = {
  [PERIOD.PRIMARY_SCHOOL]: [
    secondDifficultyDegreeObj,
    secondQuestionClassificationObj,
    secondTimeObj
  ],
  [PERIOD.SECONDARY_SCHOOL]: [
    secondDifficultyDegreeObj,
    secondQuestionClassificationObj,
    secondTimeObj
  ],
  [PERIOD.HIGH_SCHOOL]: [
    highQuestionClassificationObj,
    highDifficultyDegreeObj,
    highMethodsObj,
    highTimeObj
  ]
}
/** ================================================ 根据学段 获取筛选题目条件（单选/多选）--end ================================================ **/

/** ================================================ 根据学段 区分题目模板题型分类--start ================================================ **/
export const period2classificationList = {
  [PERIOD.PRIMARY_SCHOOL]: secondQuestionClassificationList,
  [PERIOD.SECONDARY_SCHOOL]: secondQuestionClassificationList,
  [PERIOD.HIGH_SCHOOL]: highQuestionClassificationList
}
/** ================================================ 根据学段 区分题目模板题型分类--end ================================================ **/

/** ================================================ 根据学段 区分题目模板题型难易度--start ================================================ **/
export const period2difficultyDegreeList = {
  [PERIOD.PRIMARY_SCHOOL]: secondDifficultyDegreeList,
  [PERIOD.SECONDARY_SCHOOL]: secondDifficultyDegreeList,
  [PERIOD.HIGH_SCHOOL]: highDifficultyDegreeList
}
/** ================================================ 根据学段 区分题目模板题型难易度--end ================================================ **/

/** ================================================ 题目排序--start ================================================ **/
export enum ORDER_TYPE {
  NONE = 'None', // 无
  USE_NUMBER = 'UseNumber', // 使用数目（录入使用）
  AI = 'Ai', // 综合
  UPDATE_TIME = 'UpdateTime' // 更新时间
}
/** ================================================ 题目排序--end ================================================ **/

/** ================================================ 资源类型--start ================================================ **/
export enum MATERIAL_TYPE {
  VIDEO = 'Video',
  AUDIO = 'Audio',
  IMAGE = 'Image',
  LINK = 'Hyperlinks',
  TEXT = 'Text',
  DOCUMENT = 'Document'
}
export const materialTypeList:labelValueList<MATERIAL_TYPE, 'icon'> = [
  { label: '视频', value: MATERIAL_TYPE.VIDEO, icon: 'flat-color-icons:film' },
  { label: '音频', value: MATERIAL_TYPE.AUDIO, icon: 'flat-color-icons:music' },
  { label: '图片', value: MATERIAL_TYPE.IMAGE, icon: 'flat-color-icons:gallery' },
  { label: '链接', value: MATERIAL_TYPE.LINK, icon: 'flat-color-icons:link' },
  { label: '文字', value: MATERIAL_TYPE.TEXT, icon: 'flat-color-icons:wikipedia' },
  { label: '文档', value: MATERIAL_TYPE.DOCUMENT, icon: 'flat-color-icons:document' }
]
export const materialTypeObj = {
  title: '类型',
  value: 'materialType',
  items: materialTypeList
}
export const fileTypeList = [
  { value: MATERIAL_TYPE.VIDEO, accept: 'video/*', suffixList: ['mp4', 'rm', 'rmvb', 'mov', 'm4v', 'avi', 'mkv', 'flv', 'wmv', 'webm', 'ts', 'asf', 'mpg', 'mpeg', '3gp'] },
  { value: MATERIAL_TYPE.AUDIO, accept: 'audio/*', suffixList: ['mp3', 'wav', 'ogg', 'flac', 'm4a', 'amr'] },
  { value: MATERIAL_TYPE.IMAGE, accept: 'image/*', suffixList: ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp'] },
  { value: MATERIAL_TYPE.DOCUMENT, accept: 'text/*,application/*,application/vnd.ms-powerpoint', suffixList: ['txt', 'dps', 'csv', 'et', 'xls', 'xlsx', 'dpt', 'dot', 'doct', 'docm', 'docx', 'dotm', 'dotx', 'pptx', 'wps', 'wpt', 'rtf', 'pdf', 'ppsm', 'potm', 'pptm', 'ppsx', 'pps', 'potx', 'pot'] }
]
/** ================================================ 资源类型--end ================================================ **/

/** ================================================ 资源环节模块--start ================================================ **/
export enum LINK_MODULE {
  START_UP = 'StartUp',
  BUILD = 'Build',
  CONSOLIDATE = 'Consolidate',
  APPLICATION = 'Application',
}
export const linkModuleList: labelValueList<LINK_MODULE> = [
  { label: '启动', value: LINK_MODULE.START_UP },
  { label: '构建', value: LINK_MODULE.BUILD },
  { label: '巩固', value: LINK_MODULE.CONSOLIDATE },
  { label: '运用', value: LINK_MODULE.APPLICATION }
]
export const chainModuleObj = {
  title: '环节模块',
  value: 'chainModule',
  items: linkModuleList
}
/** ================================================ 资源环节模块--end ================================================ **/

/** ================================================ 按考试--start ================================================ **/
/** 套卷类型 **/
export enum EXAMINATION_PAPER_TYPE {
  OTHER = 'Other', // 其他
  PHASE = 'Phase', // 阶段测试
  PHASE_EXERCISE = 'PhaseExercise', // 阶段练习
  MIDTERM = 'Midterm', // 期中
  FINAL = 'Final', // 期末
  THEMATIC = 'Thematic', // 专题练习
  START = 'Start', // 开学考试
  ENTRANCE = 'Entrance', // 小升初，中考备考，高考备考
  SIMULATE = 'Simulate', // 小升初模拟，中考模拟，高考模拟
  SIMULATE_ONE = 'SimulateOne', // 一模
  SIMULATE_TWO = 'SimulateTwo', // 二模
  SIMULATE_THREE = 'SimulateThree', // 三模
  FORECAST = 'Forecast', // 模拟预测
  REAL = 'Real', // 小升初真题，中考真题，高考真题
  FINISH = 'Finish', // 学业考试
  STUDYING_EXERCISE = 'StudyingExercise', // 同步练习 搜索用
  LESSON_WORK = 'LessonWork', // 课时作业
  UNIT_TEST = 'UnitTest' // 单元测试
}

const sameTestPaperMenuList = [
  { label: '同步练习', value: EXAMINATION_PAPER_TYPE.STUDYING_EXERCISE },
  { label: '阶段测试', value: EXAMINATION_PAPER_TYPE.PHASE }
]
export const periodTestType = {
  [PERIOD.PRIMARY_SCHOOL]: [
    ...sameTestPaperMenuList,
    { label: '小升初', value: EXAMINATION_PAPER_TYPE.ENTRANCE }
  ],
  [PERIOD.SECONDARY_SCHOOL]: [
    ...sameTestPaperMenuList,
    { label: '中考备考', value: EXAMINATION_PAPER_TYPE.ENTRANCE }
  ],
  [PERIOD.HIGH_SCHOOL]: [
    ...sameTestPaperMenuList,
    { label: '高考备考', value: EXAMINATION_PAPER_TYPE.ENTRANCE }
  ]
}

// 侧边栏(同步练习)
export const studyingExerciseSidebar = [
  { label: '全部', value: EXAMINATION_PAPER_TYPE.STUDYING_EXERCISE },
  { label: '课时作业', value: EXAMINATION_PAPER_TYPE.LESSON_WORK },
  { label: '单元测试', value: EXAMINATION_PAPER_TYPE.UNIT_TEST }
]

// 侧边栏(阶段测试)
export const stageTestSidebar = [
  { label: '全部', value: EXAMINATION_PAPER_TYPE.PHASE },
  { label: '阶段练习', value: EXAMINATION_PAPER_TYPE.PHASE_EXERCISE },
  { label: '期中', value: EXAMINATION_PAPER_TYPE.MIDTERM },
  { label: '期末', value: EXAMINATION_PAPER_TYPE.FINAL },
  { label: '专题练习', value: EXAMINATION_PAPER_TYPE.THEMATIC },
  { label: '开学考试', value: EXAMINATION_PAPER_TYPE.START }
]

// 侧边栏(备考)
export const simulation = [
  { label: '一模', value: EXAMINATION_PAPER_TYPE.SIMULATE_ONE },
  { label: '二模', value: EXAMINATION_PAPER_TYPE.SIMULATE_TWO },
  { label: '三模', value: EXAMINATION_PAPER_TYPE.SIMULATE_THREE },
  { label: '模拟预测', value: EXAMINATION_PAPER_TYPE.FORECAST }
]
export const primary_sidebar = [
  { label: '全部', value: EXAMINATION_PAPER_TYPE.ENTRANCE },
  { label: '小升初模拟', value: EXAMINATION_PAPER_TYPE.SIMULATE },
  { label: '小升初真题', value: EXAMINATION_PAPER_TYPE.REAL }
]
export const secondary_sidebar = [
  { label: '全部', value: EXAMINATION_PAPER_TYPE.ENTRANCE },
  { label: '中考模拟', value: EXAMINATION_PAPER_TYPE.SIMULATE, children: simulation },
  { label: '中考真题', value: EXAMINATION_PAPER_TYPE.REAL },
  { label: '学业考试', value: EXAMINATION_PAPER_TYPE.FINISH }
]
export const high_sidebar = [
  { label: '全部', value: EXAMINATION_PAPER_TYPE.ENTRANCE },
  { label: '高考模拟', value: EXAMINATION_PAPER_TYPE.SIMULATE, children: simulation },
  { label: '高考真题', value: EXAMINATION_PAPER_TYPE.REAL },
  { label: '学业考试', value: EXAMINATION_PAPER_TYPE.FINISH }
]
export const periodTestSidebar = {
  [PERIOD.PRIMARY_SCHOOL]: primary_sidebar,
  [PERIOD.SECONDARY_SCHOOL]: secondary_sidebar,
  [PERIOD.HIGH_SCHOOL]: high_sidebar
}

/** 顶部选项 */
// 备考
export const stageSelect = [secondTimeObj, provinceObj]
// 阶段测试
export const entranceSelect = {
  [PERIOD.PRIMARY_SCHOOL]: [primaryGradeObj, ...stageSelect],
  [PERIOD.SECONDARY_SCHOOL]: [secondaryGradeObj, ...stageSelect],
  [PERIOD.HIGH_SCHOOL]: [highGradeObj, ...stageSelect]
}
/** 顶部选项 */
/** ================================================ 按考试--end ================================================ **/
