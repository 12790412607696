export default defineNuxtRouteMiddleware(async(to) => {
  if (to.path.indexOf('_components') !== -1) {
    return navigateTo('/home')
  }

  switch (to.path) {
    case '/':
      if (!to.query.code) {
        return navigateTo('/home')
      }
      break
    case '/database':
      return navigateTo('/database/accordChapters')
    case '/databaseTest':
      return navigateTo('/databaseTest/accordChapters')
    case '/teachingManagement':
      return navigateTo('/teachingManagement/group')
    case '/home/classPortrait':
      return navigateTo('/home')
    case '/settings':
      return navigateTo('/settings/accountSettings')
    default:
      break
  }

  switch (to.name) {
    case 'index-home-classPortrait-groupId':
      return navigateTo(`/home/classPortrait/${to.params.groupId}/overallPortrait`)
  }
})
