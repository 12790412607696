import { defineStore } from 'pinia'

export const useTaskStatus = defineStore('taskStatus', () => {
  // 这俩玩意存这是因为layout一切换，会促使TheHeader组件重新渲染
  const oldGenTotal = ref(0) // 记录上一次已完成数据（除打印）
  const newGenTotal = ref(0) // 记录最新已完成数据（除打印）

  const oldPrintTotal = ref(0) // 记录上一次已完成打印数据
  const newPrintTotal = ref(0) // 记录最新已完成打印数据

  const storeTaskStatusData = ref<userTypes.TaskStatus[]>()

  function setOldGenTotal(state: number) {
    oldGenTotal.value = state
  }

  function setNewGenTotal(state: number) {
    newGenTotal.value = state
  }

  function setOldPrintTotal(state: number) {
    oldPrintTotal.value = state
  }

  function setNewPrintTotal(state: number) {
    newPrintTotal.value = state
  }

  function setTaskStatusData(state: userTypes.TaskStatus[]) {
    storeTaskStatusData.value = state
  }

  return {
    oldGenTotal,
    setOldGenTotal,
    newGenTotal,
    setNewGenTotal,
    oldPrintTotal,
    setOldPrintTotal,
    newPrintTotal,
    setNewPrintTotal,
    storeTaskStatusData,
    setTaskStatusData
  }
})
