import * as Reg from './reg'

export const schoolNameInExamTitleReg = Reg.schoolNameInExamTitleReg

// 姓名正则
export function uesrNameRules(rule: any, value: any, callback: any) {
  const { required } = rule
  const chineseNameReg = /^(?:[\u4e00-\u9fa5·]{2,16})$/ // 中文名正则
  const englishNameReg = /(^[a-zA-Z][a-zA-Z\s]{0,20}[a-zA-Z]$)/ // 英文名正则
  if (value) {
    if (chineseNameReg.test(value) || englishNameReg.test(value)) {
      callback()
    } else {
      callback(new Error('请输入正确姓名！'))
    }
  } else {
    required ? callback(new Error('请输入姓名！')) : callback()
  }
}

// 身份证正则
export function idCardRules(rule: any, value: any, callback: any) {
  const { required } = rule
  const idCardReg = /^[1-9]\d{5}(?:18|19|20)\d{2}(?:0[1-9]|10|11|12)(?:0[1-9]|[1-2]\d|30|31)\d{3}[\dXx]$/
  if (value) {
    if (!idCardReg.test(value)) {
      callback(new Error('请输入正确身份证号！'))
    } else {
      callback()
    }
  } else {
    required ? callback(new Error('请输入身份证号！')) : callback()
  }
}

// 手机号验证
export function phoneNumberRules(rule: any, value: any, callback: any) {
  const { required } = rule
  const phoneNumberReg = Reg.phoneNumberReg
  if (value) {
    if (!phoneNumberReg.test(value)) {
      callback(new Error('请输入正确手机号码！'))
    } else {
      callback()
    }
  } else {
    required ? callback(new Error('请输入手机号码！')) : callback()
  }
}

// 用户手机号验证，并判断是否已被注册
export function phoneNumberRepeatRules(teacherId: number) {
  return function(rule: any, value: any, callback: any) {
    const { required } = rule
    const phoneNumberReg = Reg.phoneNumberReg
    if (value) {
      if (!phoneNumberReg.test(value)) {
        callback(new Error('请输入正确手机号码！'))
      } else {
        if (!isNaN(teacherId)) {
          $utils.mitt.emit('phoneVerifyState', true)
          const reqParams = {
            id: -1,
            phone: value
          }
          if (teacherId) reqParams.id = teacherId
          $apis.teacherManage.verifyPhone(reqParams).then(res => {
            $utils.mitt.emit('phoneVerifyState', false)
            const { code } = res.data.value as any
            if (code === 1409) {
              return callback(new Error('该手机号码已存在！'))
            }
            return callback()
          })
        } else {
          callback()
        }
      }
    } else {
      required ? callback(new Error('请输入手机号码！')) : callback()
    }
  }
}

// 邮箱正则
export function emailRules(rule: any, value: any, callback: any) {
  const { required } = rule
  const emailReg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  if (value) {
    if (!emailReg.test(value)) {
      callback(new Error('请输入正确邮箱！'))
    } else {
      callback()
    }
  } else {
    required ? callback(new Error('请输入邮箱！')) : callback()
  }
}

// 班级正则
export function classRules(rule: any, value: any, callback: any) {
  const { required } = rule
  const classReg = /^[1-9][0-9]*/
  if (value) {
    if (!classReg.test(value)) {
      callback(new Error('请输入正确数字！'))
    } else {
      callback()
    }
  } else {
    required ? callback(new Error('请输入班级！')) : callback()
  }
}

// 学生9位编号正则
export function studentNumberRules(rule: any, value: any, callback: any) {
  const { required } = rule
  const studentNumberReg = /^[0-9]{9}$/
  if (value) {
    if (!studentNumberReg.test(value)) {
      callback(new Error('请输入9位学生号！'))
    } else {
      callback()
    }
  } else {
    required ? callback(new Error('请输入学生编号！')) : callback()
  }
}

// 验证端口号
export function portRules(rule: any, value: any, callback: any) {
  const { required } = rule
  const numberReg = /^\d+$/
  if (value) {
    if (!numberReg.test(value)) {
      callback(new Error('请输入纯数字！'))
    } else {
      callback()
    }
  } else {
    required ? callback(new Error('请输入端口号！')) : callback()
  }
}

// 仅英文+数字
export function codeNameRules(rule: any, value: any, callback: any) {
  const { required } = rule
  const codeNameReg = /^[A-Za-z0-9]+$/
  if (value) {
    if (!codeNameReg.test(value)) {
      callback(new Error('请输入英文和数字！'))
    } else {
      callback()
    }
  } else {
    required ? callback(new Error('请输入代号！')) : callback()
  }
}
