// 新增教师
export function addTeacher(requestData: teacherManageTypes.addTeacherInfo, key = 'addTeacher') {
  const url = 'teacher'
  return $request(key, { url }, { method: 'post', body: requestData })
}

// 编辑教师
export function editTeacher(requestData: teacherManageTypes.editTeacherInfo, key = 'editTeacher') {
  const url = 'teacher'
  return $request(key, { url }, { method: 'put', body: requestData })
}

// 删除教师
export function deleteTeacherById(requestData: teacherManageTypes.teacherId, key = 'deleteTeacherById') {
  const { id } = requestData
  const url = `teacher/${id}`
  return $request(key, { url }, { method: 'delete' })
}

// 根据id获取教师
export function findTeacherById(requestData: teacherManageTypes.teacherId, key = 'findTeacherById') {
  const { id } = requestData
  const url = `teacher/${id}`
  return $request<userTypes.TeacherInfo>(key, { url }, { method: 'get' })
}

// 获取教师列表
export function getTeachersList(requestData: teacherManageTypes.teacherInfo, key = 'getTeachersList') {
  const url = 'teacher/list'
  return $request<teacherManageTypes.getTeachersListModel>(key, { url }, { method: 'post', body: requestData })
}

// 手机号码是否重复验证
export function verifyPhone(requestData: teacherManageTypes.verifyPhone, key = 'verifyPhone') {
  const { id, phone } = requestData
  const url = `/teacher/check/${id}/${phone}`
  return $request(key, { url }, { method: 'get' })
}

// 获取教师任教班级成绩
export function getTeachingClassScore(requestData: teacherManageTypes.TeachingClassScoreReq, key = 'getTeachingClassScore') {
  const { teacherId } = requestData
  const url = `/teacher/${teacherId}/score`
  return $request<teacherManageTypes.TeachingClassScoreRes>(key, { url })
}
