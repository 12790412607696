import { acceptHMRUpdate, defineStore } from 'pinia'
import { $utils } from '../../composables/utils'

export const useUserStore = defineStore('user', () => {
  /**
   * Current named of the user.
   */

  const teachingMaterialInfo = reactive<TeachingMaterial>({
    valueList: [],
    tilteList: []
  })

  const loginState = ref()
  const userInfo = ref()
  const teacherUserInfo = ref<userTypes.TeacherInfo>()

  function setLoginState(state) {
    loginState.value = state

    $utils.setStorage('loginState', {
      idToken: state.idToken,
      authServerUrl: state.authServerUrl,
      realm: state.realm
    })
  }
  function setUserInfo(state) {
    userInfo.value = state
  }
  function setTeacherUserInfo(state: userTypes.TeacherInfo) {
    teacherUserInfo.value = state
  }

  return {
    teachingMaterialInfo,
    loginState,
    setLoginState,
    userInfo,
    setUserInfo,
    teacherUserInfo,
    setTeacherUserInfo
  }
})

if (import.meta.hot) { import.meta.hot.accept(acceptHMRUpdate(useUserStore, import.meta.hot)) }
