import * as index from './enums/index'
import * as databaseEnums from './enums/databaseEnums'
import * as teachingMaterialEnums from './enums/teachingMaterialEnums'
import * as prepareLessonsEnums from './enums/prepareLessonsEnums'
import * as userEnums from './enums/userEnums'
import * as examEnums from './enums/examEnums'
import * as printEnums from './enums/printEnums'
import * as taskStatusEnums from './enums/taskStatusEnums'
import * as booksEnums from './enums/booksEnums'

export const $enums = {
  ...index,
  ...databaseEnums,
  ...teachingMaterialEnums,
  ...prepareLessonsEnums,
  ...userEnums,
  ...examEnums,
  ...printEnums,
  ...taskStatusEnums,
  ...booksEnums
}
