import { defineStore } from 'pinia'

export const useGroup = defineStore('group', () => {
  const { getLabelWithValue } = $utils
  const { allGradeList } = $enums

  const currentRouteName = ref<string>('')
  const gradeGroup = ref<SelectOptions[]>()
  const classGroup = ref<SelectOptions[]>()

  // 获取所有年级
  async function getGradeGroup() {
    const { data, error } = await $apis.group.getGradeGroup()
    if (error.value) return

    const res = data.value as groupTypes.GradeAndClassGroup[]
    gradeGroup.value = res.map(v => {
      return {
        label: getLabelWithValue(allGradeList, v.grade),
        value: v.id
      }
    })
  }

  // 获取所有年级班级信息
  async function getGradeAndClass() {
    const { data, error } = await $apis.group.getGradeAndClass()
    if (error.value) return

    const arr: any[] = []
    const res = data.value as groupTypes.GradeAndClassGroup[]

    // 将数据处理成级联需要的格式
    res.forEach(group => {
      const hasGrade = arr.find(item => item.value === group.grade)
      if (hasGrade) {
        hasGrade.children.push({
          label: `(${group.class})班`,
          value: group.id
        })
      } else {
        arr.push({
          label: getLabelWithValue(allGradeList, group.grade),
          value: group.grade,
          children: [
            {
              label: `(${group.class})班`,
              value: group.id
            }
          ]
        })
      }
    })

    classGroup.value = arr
  }

  function setRouteName(routeName: string) {
    currentRouteName.value = routeName
  }

  function resetRouteName() {
    currentRouteName.value = ''
  }

  return {
    currentRouteName,
    gradeGroup,
    classGroup,
    setRouteName,
    getGradeGroup,
    getGradeAndClass,
    resetRouteName
  }
})
