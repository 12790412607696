import { defineNuxtPlugin } from '#app'
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.use(Viewer, {
    defaultOptions: {
      title: false, // 图片标题
      navbar: false, // 多图导航
      fullscreen: false, // 全屏
      zIndex: 3000
    }
  })
})
