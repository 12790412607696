const { authCookieName } = $enums

export function oauthLogout(): void {
  removeUserCookie()
  const loginUrl = `${useRuntimeConfig().public.VITE_KEYCLOAK_LOGIN_URL}/login/oauth/authorize?client_id=gaoshou&response_type=code&redirect_uri=${window.location.origin}&scope=openid&state=gaoshou`
  window.location.replace(loginUrl)
}

export async function getUserCookie(code: string) {
  const { error } = await $apis.user.getUserCookie(code)

  if (error.value) {
    $notify({
      type: 'error',
      message: '验证失败，请重新登录！'
    })
    oauthLogout()
  }
}

export function removeUserCookie(): void {
  const auth = useCookie(authCookieName)
  auth.value = null
}
